import axios from "axios";

const CITY_API_BASE_URL = "https://api.richgoldshine.com/bsecars/api/v1/citys";

class CityService {
  getCitys() {
    return axios.get(CITY_API_BASE_URL);
  }

  createCity(city) {
    return axios.post(CITY_API_BASE_URL, city);
  }

  getCityById(cityId) {
    return axios.get(CITY_API_BASE_URL + "/" + cityId);
  }

  updateCity(city, cityId) {
    return axios.put(CITY_API_BASE_URL + "/" + cityId, city);
  }

  deleteCity(cityId) {
    return axios.delete(CITY_API_BASE_URL + "/" + cityId);
  }
}

export default new CityService();
