import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import DashboardService from "../services/DashboardService";

import SideBarComponent from "../components/SideBarComponent";

import BackEndFooterComponent from "../components/BackEndFooterComponent";
import TopBarComponent from "../components/TopBarComponent";

class DashboardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      userReady: false,
      currentUser: { username: "" },
      totalModels: 0,
      totalBrands: 0,
      totalDealers: 0,
      totalVarients: 0,
      totalInquiry: 0,
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    //   alert(JSON.stringify(currentUser));

    if (!currentUser) this.setState({ redirect: "/home" });
    this.setState({ currentUser: currentUser, userReady: true });

    DashboardService.getDashboardData().then((res) => {
      //console.log(res.data);
      let dashboard = res.data;
      this.setState({
        totalModels: dashboard.totalModels,
        totalBrands: dashboard.totalBrands,
        totalDealers: dashboard.totalDealers,
        totalVarients: dashboard.totalVarients,
        totalInquiry: dashboard.totalInquiry,
      });
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    // const { currentUser } = this.state;

    return (
      // <div className="container-fluid">
      //   {(this.state.userReady) ?
      //   <div>
      //   <header className="jumbotron">
      //     <h3>
      //       <strong>{currentUser.username}</strong> Dashboard Profile
      //     </h3>
      //   </header>
      //   <p>
      //     <strong>Token:</strong>{" "}
      //     {currentUser.accessToken.substring(0, 20)} ...{" "}
      //     {currentUser.accessToken.substr(currentUser.accessToken.length - 20)}
      //   </p>
      //   <p>
      //     <strong>Id:</strong>{" "}
      //     {currentUser.id}
      //   </p>
      //   <p>
      //     <strong>Email:</strong>{" "}
      //     {currentUser.email}
      //   </p>
      //   <strong>Authorities:</strong>
      //   <ul>
      //     {currentUser.roles &&
      //       currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
      //   </ul>
      // </div>: null}
      // </div>

      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" class="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
              </div>

              {/* <!-- Content Row --> */}
              <div class="row">
                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-primary shadow h-100 py-2">
                    <div class="card-body">
                      <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                           <a  href="/brands">  Total Brands</a>
                          </div>
                          <div class="h5 mb-0 font-weight-bold text-gray-800">
                            {this.state.totalBrands}
                          </div>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-building fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-success shadow h-100 py-2">
                    <div class="card-body">
                      <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                            <a href="/models">Total Models</a>
                          </div>
                          <div class="h5 mb-0 font-weight-bold text-gray-800">
                            {this.state.totalModels}
                          </div>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-car fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-success shadow h-100 py-2">
                    <div class="card-body">
                      <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                            <a href="/models">Total Inquiry</a>
                          </div>
                          <div class="h5 mb-0 font-weight-bold text-gray-800">
                            {this.state.totalInquiry}
                          </div>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-car fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-info shadow h-100 py-2">
                    <div class="card-body">
                      <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                          <a href="/variants" >Total Varients</a>
                          </div>
                          <div class="row no-gutters align-items-center">
                            <div class="col-auto">
                              <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                                {this.state.totalVarients}
                              </div>
                            </div>
                            <div class="col">
                              <div class="progress progress-sm mr-2">
                                {/* <div class="progress-bar bg-info" role="progressbar"
                                                        style="width: 50%" aria-valuenow="50" aria-valuemin="0"
                                                        aria-valuemax="100"></div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Pending Requests Card Example --> */}
                <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-warning shadow h-100 py-2">
                    <div class="card-body">
                      <div class="row no-gutters align-items-center">
                        <div class="col mr-2"  href="/dealers">
                          <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                          <a href="/dealers"> Total Dealers</a>
                          </div>
                          <div class="h5 mb-0 font-weight-bold text-gray-800">
                            {this.state.totalDealers}
                          </div>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-user fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default DashboardComponent;
