import React, { Component } from "react";
import DealerService from "../services/DealerService";

import SideBarComponent from "./SideBarComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";
import {
  faEye,
  faPaperPlane,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";

class ListCarAvaibilityComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      tableData: {},
      selctedcarAvailabilityRows: [],
      carAvailability: [],
      columns: [
        {
          name: "Dealer@ Inquiry Id",
          selector: (row) => row.carAvailabilityId,
          cellExport: (row) => row.carAvailabilityId,
          sortable: true,
        },
        {
          name: " Dealer Name",
          selector: (row) => row.dealer.dealerName,
          cellExport: (row) => row.dealer.dealerName,
          sortable: true,
        },
        {
          name: "Car Name",
          selector: (row) => row.dealerInquiry.customer.inquiry,
          cellExport: (row) => row.dealerInquiry.customer.inquiry,
          sortable: true,
        },
        {
          name: "Car Available Status",
          selector: (row) => row.status,
          cellExport: (row) => row.status,
          sortable: true,
        },
        {
          name: "Date Created",
          selector: (row) => row.dateCreated,
          cellExport: (row) => row.dateCreated,
          sortable: true,
        },
      ],
    };
  }

  deleteDealer(id) {
    DealerService.deleteDealer(id).then((res) => {
      this.setState({
        dealer: this.state.dealers.filter((dealer) => dealer.dealerId !== id),
      });
    });
  }

  viewDealer(id) {
    this.props.history.push(`/view-carAvailability/${id}`);
  }
  editDealer(id) {
    this.props.history.push(`/add-carAvailability/${id}`);
  }

  componentDidMount() {
    DealerService.getCarAvailability().then((res) => {
      console.log(res.data);
      this.setState({ carAvailability: res.data });
      this.setState({
        tableData: { columns: this.state.columns, data: res.data },
      });

    });
  }

  render() {
    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" class="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Dealer</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <h6 class="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">
                        List Dealer
                        {/* <button className="btn btn-sm btn-primary" onClick={this.addDealer}> Add Dealer</button> */}
                      </h6>
                    </div>
                    <div class="card-body">
                      {/* <h2 className="text-center">Dealers List</h2> */}
                      <div className="row">
                        <DataTableExtensions {...this.state.tableData}>
                          <DataTable
                            columns={this.state.columns}
                            data={this.state.brands}
                            defaultSortField="Dealer Inquiry Id"
                            defaultSortDesc={false}
                            pagination
                            highlightOnHover
                            selectableRows
                            print={false}
                            onSelectedRowsChange={this.handleChange}
                            selectedRows={this.handleChange}
                          />
                        </DataTableExtensions>
                      </div>
                      <br></br>
                      {/* 
                       */}
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default ListCarAvaibilityComponent;
