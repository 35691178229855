import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";

import SideBarComponent from "./SideBarComponent";
import SideBarDealerComponent from "./SideBarDealerComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";
import DashboardDealerService from "../services/DashboardDealerService";
import DashboardService from "../services/DashboardService";

class DashboardDealerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      userReady: false,
      totalCustomer: 0,
      totalDealerInquiry: 0,
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    //   alert(JSON.stringify(currentUser));

    if (!currentUser) this.setState({ redirect: "/home" });
    this.setState({ currentUser: currentUser, userReady: true });

    DashboardService.getDashboardData().then((res) => {
      console.log(res.data);
      let dashboard = res.data;
      this.setState({
        // totalCustomer: dashboard.totalCustomer,
        totalDealerInquiry: dashboard.totalDealerInquiry,
      });
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    

    // const { currentUser } = this.state;

    return (
      // <div className="container-fluid">
      //   {(this.state.userReady) ?
      //   <div>
      //   <header className="jumbotron">
      //     <h3>
      //       <strong>{currentUser.username}</strong> Dashboard Profile
      //     </h3>
      //   </header>
      //   <p>
      //     <strong>Token:</strong>{" "}
      //     {currentUser.accessToken.substring(0, 20)} ...{" "}
      //     {currentUser.accessToken.substr(currentUser.accessToken.length - 20)}
      //   </p>
      //   <p>
      //     <strong>Id:</strong>{" "}
      //     {currentUser.id}
      //   </p>
      //   <p>
      //     <strong>Email:</strong>{" "}
      //     {currentUser.email}
      //   </p>
      //   <strong>Authorities:</strong>
      //   <ul>
      //     {currentUser.roles &&
      //       currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
      //   </ul>
      // </div>: null}
      // </div>

      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarDealerComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" class="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Dashboard - Dealer </h1>
              </div>

              {/* <!-- Content Row --> */}
              <div class="row">
                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-primary shadow h-100 py-2">
                    <div class="card-body">
                      <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                            Total Inquiry
                          </div>
                          <div class="h5 mb-0 font-weight-bold text-gray-800">
                            {/* {this.state.totalCustomer} */}
                            {this.state.totalDealerInquiry}
                          </div>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-calendar fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Pending Requests Card Example --> */}
                {/* <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-warning shadow h-100 py-2">
                    <div class="card-body">
                      <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                            Pending Requests
                          </div>
                          <div class="h5 mb-0 font-weight-bold text-gray-800">
                            18
                          </div>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-comments fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default DashboardDealerComponent;
