import http from "../http-common";

class UploadCityFileService {
  getFiles(cityId) {
    //  alert(JSON.stringify(modelId));
    return http.get("city_get_images/"+cityId);
  }
  uploadBaseCityImage(cityId, file, onUploadProgress) {
    let formData = new FormData();
    

    alert(JSON.stringify(cityId));
    alert(file);
    
    formData.append("file", file);
    formData.append("cityId", cityId);
    return http.post("city_base_image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  upload(cityId, idx, file, onUploadProgress) {
    let formData = new FormData();
    

    // alert(JSON.stringify(modelId));
    // alert(file);
    
    formData.append("file", file);
    formData.append("cityId", cityId);
    return http.post("city_image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }
  getFiles(cityId) {
    //  alert(JSON.stringify(modelId));
    return http.get("city_get_images/"+cityId);
  }
}

export default new UploadCityFileService();