import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import CustomerService from "../services/CustomerService";

import SideBarComponent from "./SideBarComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";

class CreateCustomerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // step 2
      id: this.props.match.params.id,
      customerName: "",
      customerNameVal: "",
      message:"",messageVal:"",
      customerEmailId: "",
      customerEmailIdVal: "",
      customerMoblieNumber: "",
      inquiry:'',
      inquiryVal:'',
      address:'',
      addressval:'',
  

      customerMoblieNumberVal: "",
      inquiryStatus: "",
      inquiryStatusVal: "",
      inquiryDescription: "",
      inquiryDescriptionVal: "",
      
    };
    this.changeCustomerNameHandler = this.changeCustomerNameHandler.bind(this);
    this.saveOrUpdateCustomer = this.saveOrUpdateCustomer.bind(this);
  }


  // step 3
  componentDidMount() {
    // step 4
    if (this.state.id === "0") {
      return;
    } else {
      CustomerService.getCustomerById(this.state.id).then((res) => {
        let customer = res.data;
        console.log("Customer ", res.data);
        this.setState({
          customerName: customer.name,
          customerEmailId: customer.email,
          customerMoblieNumber: customer.mobile,
          inquiryStatus: customer.inquiryStatus,
          inquiryDescription: customer.inquiryDescription,
          message:customer.message,
          inquiry:customer.inquiry
        });
      });
    }
  }
  saveOrUpdateCustomer = (e) => {
    e.preventDefault();
    if (this.state.customerName === "") {
      this.setState({
        customerNameVal: "Invalid!, Please enter  customer name",
      });
      return false;
    }
    if (this.state.customerEmailId === "") {
      this.setState({
        customerEmailIdVal: "Invalid!, Please enter customer email id ",
      });
      return false;
    }
    if (this.state.customerMoblieNumber === "") {
      this.setState({
        customerMoblieNumberVal:
          "Invalid!, Please enter customer mobile number ",
      });
      return false;
    }

    if (this.state.address === "") {
      this.setState({
        addressval:
          "Invalid!, Please enter customer address ",
      });
      return false;
    }
    if (this.state.inquiryStatus === "") {
      this.setState({
        inquiryStatusVal: "Invalid!, Please select inquiry status",
      });
      return false;
    }
    if (this.state.inquiryDescription === "") {
      this.setState({
        inquiryDescriptionVal: "Invalid!, Please enter inquiry description",
      });
      return false;
    }
    let customer = {
      name: this.state.customerName,
      email: this.state.customerEmailId,
      mobile: this.state.customerMoblieNumber,
      message: this.state.message,
      address: this.state.address,
      inquiryStatus: this.state.inquiryStatus,
      inquiryDescription: this.state.inquiryDescription,
      inquiry: this.state.inquiry,
    };
    console.log("customer => " + JSON.stringify(customer));

    // step 5
    if (this.state.id === "0") {
      CustomerService.createCustomer(customer).then((res) => {
        this.props.history.push("/customers");
      });
    } else {
      CustomerService.updateCustomer(customer, this.state.id).then((res) => {
        this.props.history.push("/customers");
      });
    }
  };

  changeCustomerNameHandler = (event) => {
    this.setState({ customerName: event.target.value });
  };

  changeCustomerEmailHandler = (event) => {
    this.setState({ customerEmailId: event.target.value });
  };
  changecustomerNumberHandler = (event) => {
    this.setState({ customerMoblieNumber: event.target.value });
  };

  changeCustomerAddressHandler = (event) => {
    this.setState({ address: event.target.value });
  };

  changeInquiryStatusHandler = (event) => {
    this.setState({ inquiryStatus: event.target.value });
  };
  changeInquiryDescriptionHandler = (event) => {
    this.setState({ inquiryDescription: event.target.value });
  };
  changemessageHandler = (event) => {
    this.setState({ message: event.target.value });
  };
  changeInquiryHandler = (event) => {
    this.setState({ inquiry: event.target.value });
  };

  cancel() {
    this.props.history.push("/Customers");
  }

  getTitle() {
    if (this.state.id === "0") {
      return (
        <h6 className="m-0 font-weight-bold text-primary">Add Customer</h6>
      );
    } else {
      return (
        <h6 className="m-0 font-weight-bold text-primary">Update Customer</h6>
      );
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    //     const { currentUser } = this.state;

    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" class="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Customer</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <a
                        class="btn btn-sm btn-primary float-right   mr-3"
                        href="/customers"
                        role="button"
                      >
                        List Customer
                      </a>

                     {this.getTitle()}
                    </div>
                    <div class="card-body">
                      <div className="row">
                        <div className="card col-md-6 offset-md-3 offset-md-3">
                          <div className="card-body">
                            <form>
                              <div className="form-group">
                                <label> Customer Name: </label>
                                <input
                                  placeholder="Customer Name"
                                  name="customerName"
                                  className="form-control"
                                  value={this.state.customerName}
                                  onChange={this.changeCustomerNameHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.customerNameVal}
                                </p>
                              </div>

                              <div className="form-group">
                                <label> Customer Email: </label>
                                <input
                                  placeholder="Customer Email"
                                  name="customerEmail"
                                  className="form-control"
                                  value={this.state.customerEmailId}
                                  onChange={this.changeCustomerEmailHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.customerEmailIdVal}
                                </p>
                              </div>
                              <div className="form-group">
                                <label> Customer Number: </label>
                                <input
                                  placeholder="Customer Number"
                                  name="customerNumber"
                                  className="form-control"
                                  value={this.state.customerMoblieNumber}
                                  onChange={this.changecustomerNumberHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.customerMoblieNumberVal}
                                </p>
                              </div>

                              <div className="form-group">
                                <label> Address: </label>
                                <input
                                  placeholder="Customer Address"
                                  name="customerAddress"
                                  className="form-control"
                                  value={this.state.address}
                                  onChange={this.changeCustomerAddressHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.addressval}
                                </p>
                              </div>
                              {/* <div className="form-group">
                                <label>Message: </label>
                                <input
                                  placeholder="Message"
                                  name="customerNumber"
                                  className="form-control"
                                  value={this.state.message}
                                  onChange={this.changemessageHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.message}
                                </p>
                              </div> */}



<div className="form-group">
                                <label> Customer Message: </label>
                                 <input
                                  placeholder="Customer Message"
                                  name="customerMessage"
                                  className="form-control"
                                  value={this.state.message}
                                  onChange={this.changemessageHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.messageVal}
                                </p>
                              </div>
                              <div className="form-group">
                                <label>Inquiry: </label>
                                 <input
                                  placeholder="Inquiry"
                                  name="Inquiry"
                                  className="form-control"
                                  value={this.state.inquiry}
                                  onChange={this.changeInquiryHandler}
                                />
                                <p className="text-danger">
                                  {" "}
                                  {this.state.inquiryVal}
                                </p>
                              </div>

                              <div className="form-group">
                                <label> Inquiry Status: </label>
                                <select
                                  class="form-select col-12"
                                  name="inquiryStatus"
                                  aria-label="Default select example"
                                  onChange={this.changeInquiryStatusHandler}
                                >
                                  <option value="">
                                    Select Inquiry Status
                                  </option>
                                  <option
                                    value="Cold"
                                    selected={
                                      this.state.inquiryStatus === "Cold"
                                    }
                                  >
                                    Cold
                                  </option>
                                  <option
                                    value="Warm"
                                    selected={
                                      this.state.inquiryStatus === "Warm"
                                    }
                                  >
                                    Warm
                                  </option>
                                  <option
                                    value="Hot"
                                    selected={this.state.inquiryStatus === "Hot"}
                                  >
                                    Hot
                                  </option>
                                  <option
                                    value="Contacted"
                                    selected={
                                      this.state.inquiryStatus === "Contacted"
                                    }
                                  >
                                    Contacted
                                  </option>
                                </select>
                                <p className="text-danger">
                                  {" "}
                                  {this.state.inquiryStatusVal}
                                </p>
                              </div>

                              <div className="form-group">
                                <label> Inquiry Description: </label>
                                <input
                                  placeholder="Inquiry Description"
                                  name="inquiryDescription"
                                  className="form-control"
                                  value={this.state.inquiryDescription}
                                  onChange={
                                    this.changeInquiryDescriptionHandler
                                  }
                                />
                                <p className="text-danger">
                                  {this.state.inquiryDescriptionVal}
                                </p>
                              </div>  

                              <button
                                className="btn btn-success"
                                onClick={this.saveOrUpdateCustomer}
                              >
                                Save
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={this.cancel.bind(this)}
                                style={{ marginLeft: "10px" }}
                              >
                                Cancel
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default CreateCustomerComponent;
