import React, { Component } from 'react'
import { Redirect } from "react-router-dom";
import DealerService from "../services/DealerService";
import BrandService from '../services/BrandService';
import CityService from '../services/CityService';
import SideBarComponent from './SideBarComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';
import UserService from '../services/UserService';
// import Dealer from './CreateDealerComponent';


class CreateUserComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // step 2   
            id: this.props.match.params.id,
            userEmail: '',
            userPassword: '',
            userUsername: '',
            dealer:'',
            dealerId: 0,
            brandIds: 0,
            cityIds: 0,
            brands: [],
            city: [],
        }
        this.changeUserNameHandler = this.changeUserNameHandler.bind(this);
        this.saveOrUpdateUser = this.saveOrUpdateUser.bind(this);

    }

    // step 3
    componentDidMount() {


        BrandService.getBrands().then((res) => {
            this.setState({ brands: res.data });
        });

        CityService.getCitys().then((res) => {
            this.setState({ city: res.data });
        });

        // step 4
        if (this.state.id === '0') {
            return
        } else {
            UserService.getUserById(this.state.id).then((res) => {
                let user = res.data;

                this.setState({
                    userId: user.userId,

                    // brandName: barnd.brandName,
                    // brandId: barnd?.brand?.brandId,
                    // dealerId: dealer?.dealerId,
                    // cityId: city?.cityId,
                    userEmail: user.userEmail,
                    userPassword: user.userPassword,
                    userUsername: user.userUsername,
                   


                });
            });

        }
    }
    saveOrUpdateUser = (e) => {
        e.preventDefault();
        let user = {
            userName: this.state.userName, userAddress: this.state.userAddress,
          //  dealerLattitude: this.state.dealerLattitude, dealerLongitude: this.state.dealerLongitude,
            // dealerMoblieNumber: this.state.dealerMoblieNumber,
            brand: { brandId: this.state.brandId },  city: { cityId: this.state.cityId }
        };
        console.log('user => ' + JSON.stringify(user));

        // step 5
        if (this.state.id === '0') {
            UserService.createUser(user).then(res => {
                this.props.history.push('/users');
            });
        } else {
            UserService.updateUser(user, this.state.id).then(res => {
                this.props.history.push('/users');
            });
        }
    }

    changeUserNameHandler = (event) => {
        this.setState({ userName: event.target.value });
    }
    changeBrandIdHandler = (event) => {
        this.setState({ brandId: event.target.value });
    }

    changeCityIdHandler = (event) => {
        this.setState({ cityId: event.target.value });
    }

    


    changeUserAddressHandler = (event) => {
        this.setState({ userAddress: event.target.value });
    }

    // changeDealerEmailHandler = (event) => {
    //     this.setState({ dealerEmailId: event.target.value });
    // }
    // changedealerNumberHandler = (event) => {
    //     this.setState({ dealerMoblieNumber: event.target.value });
    // }

    // changeDealerLattitudeHandler = (event) => {
    //     this.setState({ dealerLattitude: event.target.value });
    // }

    // changeDealerLongitudeHandler = (event) => {
    //     this.setState({ dealerLongitude: event.target.value });
    // }




    cancel() {
        this.props.history.push('/Users');
    }

    getTitle() {
        if (this.state.id === '0') {
            return <h6 className="m-0 font-weight-bold text-primary">Add User</h6>
            
        } else {
            return <h6 className="m-0 font-weight-bold text-primary">Update User</h6>
        }
    }

    viewUsers() {
        this.props.history.push("/users");
      }

      getTitle() {
        if (this.state.id === '0') {
            return <h6 className="m-0 font-weight-bold text-primary">Add User</h6>
        } else {
            return <h6 className="m-0 font-weight-bold text-primary">Update User</h6>
        }
      }
    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        //     const { currentUser } = this.state;

        return (

            // <!-- Page Wrapper -->
            <div id="wrapper">
                <SideBarComponent />

                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" class="d-flex flex-column">

                    {/* <!-- Main Content --> */}
                    <div id="content">

                        <TopBarComponent />


                        {/* <!-- Begin Page Content --> */}
                        <div class="container-fluid">

                            {/* <!-- Page Heading --> */}
                            {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Dealer</h1>
                
                </div> */}

                            {/* <!-- Content Row --> */}
                            {/* <!-- Content Row --> */}
                            <div class="row">

                                <div class="col-xl-12 col-lg-12">

                                    {/* <!-- Area Chart --> */}
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            <a class="btn btn-sm btn-primary float-right   mr-3" href="/users" role="button">List User</a>

                                            {this.getTitle()}
                                        </div>

                                        <div class="card-header py-3">
                      <h6 class="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">
                        View User
                        <button
                        style={{marginLeft: "850px"}}
                          className="btn btn-primary"
                          onClick={this.viewUsers}
                        >
                          {" "}
                          List User
                        </button>
                      </h6>
                    </div>
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="card col-md-6 offset-md-3 offset-md-3">

                                                    <div className="card-body">
                                                        <form>
                                                            <div className="form-group">
                                                                <label> User Name: </label>
                                                                <input placeholder="User Name" name="userName" className="form-control"
                                                                    value={this.state.userName} onChange={this.changeUserNameHandler} />
                                                            </div>
                                                            <div className="form-group">
                                                            <label> User Brand: </label>

                                                                <select class="form-select col-12" name="brandId" aria-label="Default select example" onChange={this.changeBrandIdHandler}>
                                                                    <option value="">Select Brand</option>

                                                                    {this.state.brands.map(brand =>
                                                                        <option value={brand.brandId} selected={this.state.brandId == brand.brandId}>{brand.brandName}</option>)
                                                                    }
                                                                </select>
                                                            </div>


                                                            <div className="form-group">
                                                            <label> User City: </label>

                                                                <select class="form-select col-12" name="cityId" aria-label="Default select example" onChange={this.changeCityIdHandler}>
                                                                    <option value="">Select City</option>

                                                                    {this.state.city.map(city =>
                                                                        <option value={city.cityId} selected={this.state.cityId == city.cityId}>{city.cityName}</option>)
                                                                    }
                                                                </select>
                                                            </div>

                                                            {/* <div className="form-group">
                                                                <label> Dealer Email: </label>
                                                                <input placeholder="Dealer Email" name="dealerEmail" className="form-control"
                                                                    value={this.state.dealerEmailId} onChange={this.changeDealerEmailHandler} />
                                                            </div> */}
                                                            <div className="form-group">
                                                                <label> User Number: </label>
                                                                <input placeholder="User Number" name="userNumber" className="form-control"
                                                                    value={this.state.userMoblieNumber} onChange={this.changeuserNumberHandler} />
                                                            </div>
                                                           

                                                            <button className="btn btn-success" onClick={this.saveOrUpdateDealer}>Save</button>
                                                            <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Cancel</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />



                                        </div>
                                    </div>



                                </div>


                            </div>

                            {/* <!-- Content Row --> */}


                            {/* <!-- Content Row --> */}


                        </div>
                        {/* <!-- /.container-fluid --> */}

                    </div>
                    {/* <!-- End of Main Content --> */}
                    <BackEndFooterComponent />

                </div>
                {/* <!-- End of Content Wrapper --> */}

            </div>
            // <!-- End of Page Wrapper --> 










        );
    }
}

  export default CreateUserComponent;