import React, { Component } from 'react'


class SideBarDealerComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            permission : localStorage.getItem("user")

        }




    }


    
  componentDidMount(){


//     alert("permission " + this.state.permission);
//   console.log("permission " + this.state.permission)
          
}

    render() {
        return (
            <div>

                {/* <!-- Sidebar --> */}
                <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

                    {/* <!-- Sidebar - Brand --> */}
                    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="/dashboardDealer">
                        <div class="sidebar-brand-icon rotate-n-15">
                            <i class="fas fa-car"></i>
                        </div>
                        <div class="sidebar-brand-text mx-3">BSE Cars</div>
                    </a>

                    {/* <!-- Divider --> */}
                    <hr class="sidebar-divider my-0" />

                    {/* <!-- Nav Item - Dashboard --> */}
                    <li class="nav-item active">
                        <a class="nav-link" href="#">
                            <i class="fas fa-fw fa-tachometer-alt"></i>
                            <span>Dashboard</span></a>
                    </li>

                    {/* <!-- Divider --> */}
                    <hr class="sidebar-divider" />

                    {/* <!-- Heading --> */}
                    <div class="sidebar-heading">
                        Car Inquiry
                    </div>
                    


                  {/* <!-- Nav Item - Dealer Inquiry Pages Collapse Menu --> */}


                  <li class="nav-item">
                        <a class="nav-link collapsed" href="#!" data-toggle="collapse" data-target="#collapseDealerInquiryPages"
                            aria-expanded="true" aria-controls="collapseDealerInquiryPages">
                            <i class="fas fa-user"></i>
                            <span>Dealer Inquiry</span>
                        </a>
                        <div id="collapseDealerInquiryPages" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                            <div class="bg-white py-2 collapse-inner rounded">
                                <a class="collapse-item" href="/dealerbackend-inquiry">Inquiry</a>

                            </div>
                        </div>
                    </li>





                
                 




                   







                 



                 

                 




                    {/* <!-- Nav Item - Booking --> */}
                    {/* <li class="nav-item">
    <a class="nav-link" href="charts.html">
    <i class="fas fa-book"></i>
            <span>Booking</span></a>
</li> */}

                    {/* <!-- Nav Item - Management --> */}
                    {/* <li class="nav-item">
    <a class="nav-link" href="tables.html">
    <i class="fas fa-tasks"></i>
        <span>Management</span></a>
</li> */}
                    {/* <!-- Nav Item - Report --> */}
                    {/* <li class="nav-item">
    <a class="nav-link" href="tables.html">
        <i class="fas fa-fw fa-table"></i>
        <span>Report</span></a>
</li> */}

                    {/* <!-- Nav Item - Setting --> */}
                    {/* <li class="nav-item">
    <a class="nav-link" href="tables.html">
    <i class="fas fa-cog"></i>
        <span>Setting</span></a>
</li> */}

                    {/* <!-- Divider --> */}
                    <hr class="sidebar-divider d-none d-md-block" />

                    {/* <!-- Sidebar Toggler (Sidebar) --> */}
                    {/* <div class="text-center d-none d-md-inline">
    <button class="rounded-circle border-0" id="sidebarToggle"></button>
</div> */}


                </ul>
                {/* <!-- End of Sidebar --> */}



            </div>
        )
    }
}

export default SideBarDealerComponent
