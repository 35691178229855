import React, { Component } from "react";

class SideBarComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permission: localStorage.getItem("user"),
    };
  }

  componentDidMount() {
    //     alert("permission " + this.state.permission);
    //   console.log("permission " + this.state.permission)
  }

  render() {
    return (
      <div>
        {/* <!-- Sidebar --> */}
        <ul
          className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
          id="accordionSidebar"
        >
          {/* <!-- Sidebar - Brand --> */}
          <a
            className="sidebar-brand d-flex align-items-center justify-content-center"
            href="/dashboard"
          >
            <div className="sidebar-brand-icon rotate-n-15">
              <i className="fas fa-car"></i>
            </div>
            <div className="sidebar-brand-text mx-3">BSE Cars</div>
          </a>

          {/* <!-- Divider --> */}
          <hr className="sidebar-divider my-0" />

          {/* <!-- Nav Item - Dashboard --> */}
          <li className="nav-item active">
            <a className="nav-link" href="/dashboard">
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </a>
          </li>

          {/* <!-- Divider --> */}
          <hr className="sidebar-divider" />

          {/* <!-- Heading --> */}
          <div className="sidebar-heading">Car Inquiry</div>

          {/* <!-- Nav Item - Customer Pages Collapse Menu --> */}
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="/customers"
              aria-expanded="true"
              aria-controls="collapseInquriyPages"
            >
              <i className="fas fa-user-circle"></i>
              <span>Inquiry</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="/carAvailability"
              aria-expanded="true"
              aria-controls="collapseInquriyPages"
            >
              <i className="fas fa-fw fa-taxi"></i>
              <span>Car Availability</span>
            </a>
          </li>

          <hr className="sidebar-divider d-none d-md-block" />

          {/* <!-- Nav Item - Customer Pages Collapse Menu --> */}
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseCustomerPages"
              aria-expanded="true"
              aria-controls="collapseCustomerPages"
            >
              <i className="fas fa-user-circle"></i>
              <span>Customer</span>
            </a>
            <div
              id="collapseCustomerPages"
              className="collapse"
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <a className="collapse-item" href="/add-customer/0">
                  Add Customer
                </a>
                <a className="collapse-item" href="/customers">
                  List customers
                </a>
              </div>
            </div>
          </li>
          {/* <li className="nav-item">
                        <a className="nav-link collapsed" href="#!" data-toggle="collapse" data-target="#collapseEquipment"
                            aria-expanded="true" aria-controls="collapseEquipment">
                            <i className="fas fa-fw fa-car"></i>
                            <span>User</span>
                        </a>
                        <div id="collapseEquipment" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <a className="collapse-item" href="/add-user/0">Add User</a>
                                <a className="collapse-item" href="/User">List User</a>
                            </div>
                        </div>
                    </li> */}

          <hr className="sidebar-divider d-none d-md-block" />

          <div className="sidebar-heading">Car DETAILS</div>

          {/* <!-- Nav Item - Pages Collapse Menu --> */}
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              <i className="fas fa-fw fa-car"></i>
              <span>Brand</span>
            </a>
            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <a className="collapse-item" href="/add-brand/0">
                  Add Brand
                </a>
                <a className="collapse-item" href="/brands">
                  List Brand
                </a>
              </div>
            </div>
          </li>

          {/* <!-- Nav Item - Utilities Collapse Menu --> */}
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseUtilities"
              aria-expanded="true"
              aria-controls="collapseUtilities"
            >
              <i className="fas fa-fw fa-taxi"></i>
              <span>Model</span>
            </a>
            <div
              id="collapseUtilities"
              className="collapse"
              aria-labelledby="headingUtilities"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <a className="collapse-item" href="/add-model/0">
                  Add Model
                </a>
                <a className="collapse-item" href="/models">
                  List Model
                </a>
              </div>
            </div>
          </li>

          {/* <!-- Nav Item - Variant Collapse Menu --> */}
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseVariant"
              aria-expanded="true"
              aria-controls="collapseVariant"
            >
              <i className="fas fa-fw fa-taxi"></i>
              <span>Variant</span>
            </a>
            <div
              id="collapseVariant"
              className="collapse"
              aria-labelledby="headingUtilities"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <a className="collapse-item" href="/add-variant/0">
                  Add Variant
                </a>
                <a className="collapse-item" href="/variants">
                  List Variant
                </a>
              </div>
            </div>
          </li>

          {/* <!-- Nav Item - Specification Collapse Menu --> */}
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseSpecification"
              aria-expanded="true"
              aria-controls="collapseSpecification"
            >
              <i className="fas fa-fw fa-taxi"></i>
              <span>Specification</span>
            </a>
            <div
              id="collapseSpecification"
              className="collapse"
              aria-labelledby="headingUtilities"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <a className="collapse-item" href="/add-specification/0">
                  Add Specification
                </a>
                <a className="collapse-item" href="/specifications">
                  List Specification
                </a>
              </div>
            </div>
          </li>

          {/* <!-- Nav Item - Features Collapse Menu --> */}
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseFeature"
              aria-expanded="true"
              aria-controls="collapseFeature"
            >
              <i className="fas fa-fw fa-taxi"></i>
              <span>Feature</span>
            </a>
            <div
              id="collapseFeature"
              className="collapse"
              aria-labelledby="headingUtilities"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <a className="collapse-item" href="/add-feature/0">
                  Add Feature
                </a>
                <a className="collapse-item" href="/features">
                  List Feature
                </a>
              </div>
            </div>
          </li>

          {/* <!-- Nav Item - Pages Collapse Menu --> */}
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseColor"
              aria-expanded="true"
              aria-controls="collapseColor"
            >
              <i className="fas fa-fw fa-car"></i>
              <span>Color</span>
            </a>
            <div
              id="collapseColor"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <a className="collapse-item" href="/add-color/0">
                  Add Color
                </a>
                <a className="collapse-item" href="/colors">
                  List Colors
                </a>
              </div>
            </div>
          </li>
          {/* <li className="nav-item">
                        <a className="nav-link collapsed" href="#!" data-toggle="collapse" data-target="#collapseTestimonial"
                            aria-expanded="true" aria-controls="collapseTestimonial">
                            <i className="fas fa-fw fa-car"></i>
                            <span>Testimonial</span>
                        </a>
                        <div id="collapseTestimonial" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <a className="collapse-item" href="/add-testimonial/0">Add Testimonial</a>
                                <a className="collapse-item" href="/testimonial">List Testimonials</a>
                            </div>
                        </div>
                    </li> */}

          {/* <!-- Nav Item - Pages Collapse Menu --> */}
          {/* <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseEquipment"
              aria-expanded="true"
              aria-controls="collapseEquipment"
            >
              <i className="fas fa-fw fa-car"></i>
              <span>Equipment</span>
            </a>
            <div
              id="collapseEquipment"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <a className="collapse-item" href="/add-equipment/0">
                  Add Equipment
                </a>
                <a className="collapse-item" href="/equipments">
                  List Equipment
                </a>
              </div>
            </div>
          </li> */}

          {/* <!-- Divider --> */}
          <hr className="sidebar-divider" />

          {/* <!-- Heading --> */}
          <div className="sidebar-heading">OUR TEAM</div>
          {/* <li class="nav-item">
            <a
              class="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseUserPages"
              aria-expanded="true"
              aria-controls="collapseUserPages"
            >
              <i class="fa fa-users" aria-hidden="true"></i>
              <span>User</span>
            </a>
            <div
              id="collapseUserPages"
              class="collapse"
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div class="bg-white py-2 collapse-inner rounded">
                <a class="collapse-item" href="/add-user/0">
                  Add User
                </a>
                <a class="collapse-item" href="/users">
                  List User
                </a>
              </div>
            </div>
          </li> */}

          {/* <!-- Nav Item - Dealer Pages Collapse Menu --> */}
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseDealerPages"
              aria-expanded="true"
              aria-controls="collapseDealerPages"
            >
              <i className="fas fa-user"></i>
              <span>Dealer</span>
            </a>
            <div
              id="collapseDealerPages"
              className="collapse"
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <a className="collapse-item" href="/add-dealer/0">
                  Add Dealer
                </a>
                <a className="collapse-item" href="/dealers">
                  List Dealer
                </a>
              </div>
            </div>
          </li>

          {/* <!-- Nav Item - Executive Pages Collapse Menu --> */}
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseExecutivePages"
              aria-expanded="true"
              aria-controls="collapseExecutivePages"
            >
              <i className="fas fa-fw fa-folder"></i>
              <span>Executive</span>
            </a>
            <div
              id="collapseExecutivePages"
              className="collapse"
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <a className="collapse-item" href="/add-executive/0">
                  Add Executive
                </a>
                <a className="collapse-item" href="/executives">
                  List Executive
                </a>
              </div>
            </div>
          </li>

          <hr className="sidebar-divider" />
          <div className="sidebar-heading">lOCATIONS</div>

          {/* <!-- Nav Item - Customer Pages Collapse Menu --> */}
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseCitiesPages"
              aria-expanded="true"
              aria-controls="collapseCitiesPages"
            >
              <i className="fas fa-user-circle"></i>
              <span>Cities</span>
            </a>
            <div
              id="collapseCitiesPages"
              className="collapse"
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <a className="collapse-item" href="/add-city/0">
                  Add city
                </a>
                <a className="collapse-item" href="/citys">
                  List Cities
                </a>
              </div>
            </div>
          </li>

          {/* <!-- Nav Item - Customer Pages Collapse Menu --> */}
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#!"
              data-toggle="collapse"
              data-target="#collapseStatesPages"
              aria-expanded="true"
              aria-controls="collapseStatesPages"
            >
              <i className="fas fa-user-circle"></i>
              <span>States</span>
            </a>
            <div
              id="collapseStatesPages"
              className="collapse"
              aria-labelledby="headingPages"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <a className="collapse-item" href="/add-state/0">
                  Add State
                </a>
                <a className="collapse-item" href="/states">
                  List States
                </a>
              </div>
            </div>
          </li>

          {/* <!-- Nav Item - Booking --> */}
          {/* <li className="nav-item">
    <a className="nav-link" href="charts.html">
    <i className="fas fa-book"></i>
            <span>Booking</span></a>
</li> */}

          {/* <!-- Nav Item - Management --> */}
          {/* <li className="nav-item">
    <a className="nav-link" href="tables.html">
    <i className="fas fa-tasks"></i>
        <span>Management</span></a>
</li> */}
          {/* <!-- Nav Item - Report --> */}
          {/* <li className="nav-item">
    <a className="nav-link" href="tables.html">
        <i className="fas fa-fw fa-table"></i>
        <span>Report</span></a>
</li> */}

          {/* <!-- Nav Item - Setting --> */}
          {/* <li className="nav-item">
    <a className="nav-link" href="tables.html">
    <i className="fas fa-cog"></i>
        <span>Setting</span></a>
</li> */}

          {/* <!-- Divider --> */}
          <hr className="sidebar-divider d-none d-md-block" />

          {/* <!-- Sidebar Toggler (Sidebar) --> */}
          {/* <div className="text-center d-none d-md-inline">
    <button className="rounded-circle border-0" id="sidebarToggle"></button>
</div> */}
        </ul>
        {/* <!-- End of Sidebar --> */}
      </div>
    );
  }
}

export default SideBarComponent;
