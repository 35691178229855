import axios from "axios";

const BRAND_API_BASE_URL =
  "https://api.richgoldshine.com/bsecars/api/v1/features";

class FeatureService {
  getFeatures() {
    return axios.get(BRAND_API_BASE_URL);
  }

  createFeature(feature) {
    return axios.post(BRAND_API_BASE_URL, feature);
  }

  getFeatureById(featureId) {
    return axios.get(BRAND_API_BASE_URL + "/" + featureId);
  }

  updateFeature(feature, featureId) {
    return axios.put(BRAND_API_BASE_URL + "/" + featureId, feature);
  }

  deleteFeature(featureId) {
    return axios.delete(BRAND_API_BASE_URL + "/" + featureId);
  }
}

export default new FeatureService();
