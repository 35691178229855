import axios from "axios";

const FEATURE_VALUE_API_BASE_URL =
  "https://api.richgoldshine.com/bsecars/api/v1/featureValues";

class FeatureValueService {
  getFeatureValues() {
    return axios.get(FEATURE_VALUE_API_BASE_URL);
  }

  createFeatureValue(featureValue) {
    return axios.post(FEATURE_VALUE_API_BASE_URL, featureValue);
  }

  getFeatureValueById(featureValueId) {
    return axios.get(FEATURE_VALUE_API_BASE_URL + "/" + featureValueId);
  }

  updateFeatureValue(featureValue, featureValueId) {
    return axios.put(
      FEATURE_VALUE_API_BASE_URL + "/" + featureValueId,
      featureValue
    );
  }

  deleteFeatureValue(featureValueId) {
    return axios.delete(FEATURE_VALUE_API_BASE_URL + "/" + featureValueId);
  }
}

export default new FeatureValueService();
