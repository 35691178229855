import React, { Component } from 'react'
import VariantService from '../services/VariantService';
import ModelService from '../services/ModelService';
import ColorService from '../services/ColorService';
import EquipmentService from '../services/EquipmentService';
import SideBarComponent from './SideBarComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';
import Multiselect from 'multiselect-react-dropdown';





class CreateVariantComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // step 2
            id: this.props.match.params.id,
            variantName: '',   variantNameVal: '',
            fuelType: '',    fuelTypeVal: '',
            transmission: '',   transmissionVal: '',
            exShowroom: '',   exShowroomVal: '',
            regAmount: '', regAmountVal: '',
            insPayment: '',  insPaymentVal: '',
            autoCard: '', 
            fastTag: '',   
            tcs: '',  
        // offer: '',offerVal:'',
        featuredCars:'', featuredCarsVal:'',



            discount: '', 
            modelId: '', 
            models: [],  message: '', modelVal: '',
            options: [{ name: 'Option 1️⃣', id: 1 }, { name: 'Option 2️⃣', id: 2 }],
            colors: [],  message: '', colorVal: '',
            equipments: [], 
            allColors: [],
            allEquipments: [],
            selectedValues: [],
            // offer:[],

        }
        this.saveOrUpdateVariant = this.saveOrUpdateVariant.bind(this);


    }


    // step 3
    componentDidMount() {

   

        ModelService.getModels().then((res) => {
            this.setState({ models: res.data });
        });


        ColorService.getColors().then((res) => {
            this.setState({ allColors: res.data });
        });

        EquipmentService.getEquipments().then((res) => {
            this.setState({allEquipments: res.data });
        });

        // step 4
        if (this.state.id === '0') {
            return
        } else {
            VariantService.getVariantById(this.state.id).then((res) => {
                let variant = res.data;
                this.setState({
                    variantName: variant.variantName,
                    modelId: variant.model.modelId,
                    fuelType: variant.fuelType,
                    transmission: variant.transmission,
                    exShowroom: variant.exShowroom,
                    regAmount: variant.regAmount,
                    insPayment: variant.insPayment,
                    autoCard: variant.autoCard,
                    fastTag: variant.fastTag,
                    tcs: variant.tcs,
                    discount: variant.discount,
                    colors: variant.colors,
                    equipments: variant.equipments,
                    // offer: variant.offer,
                    featuredCars: variant.featuredCars
                });
            });
        }


    }
    saveOrUpdateVariant = (e) => {
        e.preventDefault();
     //   alert(JSON.stringify(this.state.colors));
//alert(JSON.stringify(this.state.equipments));
if (this.state.variantName === '') {
    this.setState({variantNameVal: 'Invalid!, Please enter  variant name'})
    return false;
} 
if (this.state.fuelType === '') {
    this.setState({fuelTypeVal: 'Invalid!, Please select fuel type  '})
    return false;
} 
if (this.state.transmission === '') {
    this.setState({transmissionVal: 'Invalid!, Please select transmission  '})
    return false;
} 
if (this.state.exShowroom === '') {
    this.setState({exShowroomVal: 'Invalid!, Please enter ex showroom '})
    return false;
} 
if (this.state.regAmount === '') {
    this.setState({regAmountVal: 'Invalid!, Please enter registration amount'})
    return false;
}
if (this.state.insPayment === '') {
    this.setState({insPaymentVal: 'Invalid!, Please enter insurance amount'})
    return false;
} 
if (this.state.modelId === '') {
    this.setState({modelsVal: 'Invalid!, Please select model '})
    return false;
} 


// alert(this.state.offer);
if (this.state.colors == '') {
    this.setState({colorsVal: 'Invalid!, Please select color'})
    return false;
} 
// if (this.state.offer == '') {
//     this.setState({offerVal: 'Invalid!, Please select offer'})
//     return false;
// } 


    

alert("carsrs" +this.state.featuredCars);
/// colors arraylist
        let variant = {
            variantId: this.state.id,
            variantName: this.state.variantName,
            fuelType: this.state.fuelType,
            transmission: this.state.transmission,
            exShowroom: this.state.exShowroom,
            regAmount: this.state.regAmount,
            insPayment: this.state.insPayment,
            autoCard: this.state.autoCard,
            fastTag: this.state.fastTag,
            tcs: this.state.tcs,
            discount: this.state.discount,
            colors: this.state.colors,

            featuredCars:this.state.featuredCars,
            // equipments: this.state.equipments,
            model: { modelId: this.state.modelId }
        };

//    alert('@@@variant => ' + JSON.stringify(variant));
      //  console.log('variant => ' + JSON.stringify(variant));

        // step 5
        if (this.state.id === '0') {
         //   alert('variant => ');
            VariantService.createVariant(variant).then(res => {
                this.props.history.push('/variants');
            });
        } else {

        //    alert('variant ############=> ');
            VariantService.updateVariant(variant, this.state.id).then(res => {
                this.props.history.push('/variants');
            });
        }
    }

    changeVariantNameHandler = (event) => {
        this.setState({ variantName: event.target.value });
    }
   
    changeFeaturedCarsHandler = (event) => {
        this.setState({ featuredCars: event.target.value });
    }
    // onSelect1 = (selectedList, selectedItem) => {
    //   //  alert(JSON.stringify(selectedList));
    //     this.setState({ equipments: selectedList });
    // }

    onSelect = (selectedList, selectedItem) => {
        this.setState({ colors: selectedList });

    }


   
    changeModelIdHandler = (event) => {
        this.setState({ modelId: event.target.value });
    }
    changeFuelTypeHandler = (event) => {
        this.setState({ fuelType: event.target.value });
    }
    
    changeTransmissionHandler = (event) => {
        this.setState({ transmission: event.target.value });
    }
    changeonSelecteHandler = (event) => {
        this.setState({ colors: event.target.value });
    }

    changeonSelecteHandler1 = (event) => {
        this.setState({ equipments: event.target.value });
    }

    changeExShowroomHandler = (event) => {
        this.setState({ exShowroom: event.target.value });
    }

    changeRegAmountHandler = (event) => {
        this.setState({ regAmount: event.target.value });
    }
    changeInsPaymentHandler = (event) => {
        this.setState({ insPayment: event.target.value });
    }
    changeAutoCardHandler = (event) => {
        this.setState({ autoCard: event.target.value });
    }
    changeFastTagHandler = (event) => {
        this.setState({ fastTag: event.target.value });

    }
    changeTcsHandler = (event) => {
        this.setState({ tcs: event.target.value });
    }
    changeDiscountHandler = (event) => {
        this.setState({ discount: event.target.value });
    }

    cancel() {
        this.props.history.push('/variants');
    }

    getTitle() {
        if (this.state.id === '0') {
            return <h6 className="m-0 font-weight-bold text-primary">Add Variant</h6>
        } else {
            return <h6 className="m-0 font-weight-bold text-primary">Update Variant</h6>
        }
    }
    render() {
        return (
            // <!-- Page Wrapper -->
            <div id="wrapper">

                <SideBarComponent />

                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" class="d-flex flex-column">

                    {/* <!-- Main Content --> */}
                    <div id="content">

                        <TopBarComponent />


                        {/* <!-- Begin Page Content --> */}
                        <div class="container-fluid">

                            {/* <!-- Page Heading --> */}
                            {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Variant</h1>
                
                </div> */}

                            {/* <!-- Content Row --> */}
                            {/* <!-- Content Row --> */}
                            <div class="row">

                                <div class="col-xl-12 col-lg-12">

                                    {/* <!-- Area Chart --> */}
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                        <a class="btn btn-sm btn-primary float-right   mr-3" href="/variants" role="button">List Variants</a>

                                            {this.getTitle()}
                                        </div>
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="card col-md-6 ">

                                                    <div className="card-body">
                                                        <form>
                                                            <div className="form-group">
                                                                <label> Variant Name: </label>
                                                                <input placeholder="Variant Name" name="variantName" className="form-control"
                                                                    value={this.state.variantName} onChange={this.changeVariantNameHandler} />
                                                                    <p className="text-danger"> {this.state.variantNameVal }</p>
                                                            </div>


                                                            <div className="form-group">
                                                                <label> Model: </label>
                                                                <select class="form-select col-12" name="modelId" aria-label="Default select example" onChange={this.changeModelIdHandler}>

                                                                    <option value="">Select Model</option>
                                                                    {this.state.models.map(model =>
                                                                        <option value={model.modelId} selected={this.state.modelId == model.modelId}>{model.brand.brandName}=={model.modelName}</option>)
                                                                    }
                                                                </select>
                                                                <p className="text-danger"> {this.state.modelsVal }</p>
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Fuel Type: </label>
                                                                <select class="form-select col-12" name="fuelType" aria-label="Default select example" onChange={this.changeFuelTypeHandler}>
                                                                    <option value="">Select Fuel Type</option>
                                                                    <option value="Petrol" selected={this.state.fuelType == 'Petrol'}>Petrol</option>
                                                                    <option value="CNG" selected={this.state.fuelType == 'CNG'}>CNG</option>
                                                                    <option value="Diesel" selected={this.state.fuelType == 'Diesel'}>Diesel</option>
                                                                    <option value="Electric" selected={this.state.fuelType == 'Electric'}>Electric </option>


                                                                </select>
                                                                <p className="text-danger"> {this.state.fuelTypeVal }</p>
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Transmission: </label>
                                                                <select class="form-select col-12" name="transmission" aria-label="Default select example" onChange={this.changeTransmissionHandler}>
                                                                    <option value="">Select Transmission</option>
                                                                    <option value="Automatic" selected={this.state.transmission == 'Automatic'}>Automatic</option>
                                                                    <option value="Manual" selected={this.state.transmission == 'Manual'}>Manual</option>
                                                                </select>
                                                                <p className="text-danger"> {this.state.transmissionVal }</p>
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Ex Showroom: </label>
                                                                <input placeholder="Ex Showroom" name="exShowroom" className="form-control"
                                                                    value={this.state.exShowroom} onChange={this.changeExShowroomHandler} />
                                                                      <p className="text-danger"> {this.state.exShowroomVal }</p>
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Registration Amount: </label>
                                                                <input placeholder="Registration Amount" name="regAmount" className="form-control"
                                                                    value={this.state.regAmount} onChange={this.changeRegAmountHandler} />
                                                                      <p className="text-danger"> {this.state.regAmountVal }</p>
                                                            </div>

                                                        </form>
                                                    </div>
                                                </div>


                                                <div className="card col-md-6 ">

                                                    <div className="card-body">
                                                        <form>

                                                           
                                                            
                                                            <div className="form-group">
                                                                <label> Insurance Payment: </label>
                                                                <input placeholder="Insurance Payment" name="insPayment" className="form-control"
                                                                    value={this.state.insPayment} onChange={this.changeInsPaymentHandler} />
                                                                      <p className="text-danger"> {this.state.insPaymentVal }</p>
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Auto Card: </label>
                                                                <input placeholder="Auto Card" name="autoCard" className="form-control"
                                                                    value={this.state.autoCard} onChange={this.changeAutoCardHandler} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Fast Tag: </label>
                                                                <input placeholder="Fast Tag" name="fastTag" className="form-control"
                                                                    value={this.state.fastTag} onChange={this.changeFastTagHandler} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Tcs: </label>
                                                                <input placeholder="Tcs " name="tcs" className="form-control"
                                                                    value={this.state.tcs} onChange={this.changeTcsHandler} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Discount: </label>
                                                                <input placeholder="Discount" name="discount" className="form-control"
                                                                    value={this.state.discount} onChange={this.changeDiscountHandler} />
                                                            </div>

                                                            <div className="form-group">
                                                                <label> Color: </label>
                                                            <Multiselect onChange={this.changeonSelecteHandler} 
                                                                options={this.state.allColors} // Options to display in the dropdown
                                                                selectedValues={this.state.colors} // Preselected value to persist in dropdown
                                                                onSelect={this.onSelect} // Function will trigger on select event
                                                                onRemove={this.onRemove} // Function will trigger on remove event
                                                                displayValue="colorName" // Property name to display in the dropdown options
                                                            /> 
                                                              <p className="text-danger"> {this.state.colorVal }</p>
                                                            </div>

                                                         
                                                            <div className="form-group">
                                                                <label> Featured Cars: </label>
                                                                {/* <input placeholder="offer" name="offer" className="form-control"
                                                                    value={this.state.offer} onChange={this.changeOfferHandler} />
                                                                      <p className="text"> {this.state.offerVal }</p> */}
                                                                <select class="form-select col-12" name="Featured Cars" aria-label="Default select example"
                                                                 onChange={this.changeFeaturedCarsHandler}>
                                                                    <option value="">Select Featured Cars</option>
                                                                    <option value="Todays Offers" >Todays Offers</option>
                                                                    <option value="Upcoming" >Upcoming</option>
                                                                    <option value="Popular" >Popular</option>
                                                                    <option value="Latest" >Latest</option>
                                                                    


                                                                </select>
                                                                <p className="text-danger"> {this.state.featuredCarsVal }</p>
                                                            </div>
{/* 
{/* 
                                                            <Multiselect
                                                                options={this.state.allEquipments} // Options to display in the dropdown
                                                                selectedValues={this.state.equipments} // Preselected value to persist in dropdown
                                                                onSelect={this.onSelect1} // Function will trigger on select event
                                                                onRemove={this.onRemove} // Function will trigger on remove event
                                                                displayValue="equipmentName" // Property name to display in the dropdown options
                                                            />  */}

                                                            
                                                        </form>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                           
                                            <div className='text-center mt-4'>
                                                        <button className="btn btn-success pl-5 pr-5" onClick={this.saveOrUpdateVariant}> <b>Save</b></button>
                                                            <button className="btn btn-danger pl-5 pr-5" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}><b>Cancel</b></button>

                                                            </div>
                                                            <hr />



                                        </div>
                                       
                                      
                                    </div>



                                </div>


                            </div>

                            {/* <!-- Content Row --> */}


                            {/* <!-- Content Row --> */}


                        </div>
                        {/* <!-- /.container-fluid --> */}

                    </div>
                    {/* <!-- End of Main Content --> */}
                    <BackEndFooterComponent />

                </div>
                {/* <!-- End of Content Wrapper --> */}

            </div >
            // <!-- End of Page Wrapper --> 


        )
    }
}

export default CreateVariantComponent
