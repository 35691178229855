import axios from "axios";

const EXECUTIVE_API_BASE_URL =
  "https://api.richgoldshine.com/bsecars/api/v1/executives";

class ExecutiveService {
  getExecutives() {
    return axios.get(EXECUTIVE_API_BASE_URL);
  }

  createExecutive(executive) {
    return axios.post(EXECUTIVE_API_BASE_URL, executive);
  }

  getExecutiveById(executiveId) {
    return axios.get(EXECUTIVE_API_BASE_URL + "/" + executiveId);
  }

  updateExecutive(executive, executiveId) {
    return axios.put(EXECUTIVE_API_BASE_URL + "/" + executiveId, executive);
  }

  deleteExecutive(executiveId) {
    return axios.delete(EXECUTIVE_API_BASE_URL + "/" + executiveId);
  }
}

export default new ExecutiveService();
