import React, { Component } from "react";
import FooterComponent from "./FooterComponent";
import VariantService from "../services/VariantService";
import FrontEndFooterComponent from "./FrontEndFooterComponent";
import FrontTopBarComponent from "./FrontTopBarComponent";
import ModelService from "../services/ModelService";
import BrandService from "../services/BrandService";
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../services/auth.service";
import CityService from "../services/CityService";
import ScriptTag from "react-script-tag";

class CompareCarComponent extends Component {
  constructor(props) {
    super(props);

    this.xLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.onChangeSearchModel = this.onChangeSearchModel.bind(this);
    this.retrieveModels = this.retrieveModels.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveModel = this.setActiveModel.bind(this);
    this.removeAllModels = this.removeAllModels.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",
      models1: [],
      variant: {},
      variants: [],
      models2: [],
      models3: [],
      models4: [],
      variants1: [],
      variants2: [],
      variants3: [],
      variants4: [],
      brands: [],
      currentModel: null,
      currentIndex: -1,
      searchModel: "",
      page: 1,
      count: 0,
      pageSize: 6,
      brandId1: "",
      brandId2: "",
      brandId3: "",
      brandId4: "",
      modelId: "",
      modelId1: "",
      modelId2: "",
      modelId3: "",
      modelId4: "",
      variantId1: "",
      variantId2: "",
      variantId3: "",
      variantId4: "",
      variant1: {},
      variant2: {},
      variant3: {},
      variant4: {},
      fuelType: "",
      transmission: "",

      options: [
        { name: "Pune", id: 1 },
        { name: "Mumbai", id: 2 },
        { name: "Nagpur", id: 3 },
        { name: "Mumbai", id: 4 },
        { name: "Aurangabad ", id: 5 },
      ],
      city: [],
    };

    this.pageSizes = [6, 10, 20];
  }

  displayModel(id) {
    this.props.history.push(`/display-variant-New/${id}`);
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.username, this.state.password).then(
        () => {
          this.props.history.push("/dashboard");
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  componentDidMount() {
  //  alert("load");
    BrandService.getBrands().then((res) => {
      this.setState({ brands: res.data });
      // alert("Loading brands");
      // alert(JSON.stringify(res.data));
    });
    ModelService.getModels().then((res) => {
      this.setState({ models: res.data });
    });

    CityService.getCitys().then((res) => {
      this.setState({ city: res.data });
    });

    this.retrieveModels();
  }

  onChangeSearchModel(e) {
    const searchModel = e.target.value;

    this.setState({
      searchModel: searchModel,
    });
  }

  onChangeBrandId1Handler = (event) => {
    //alert('Hello' + event.target.value);

    this.setState({ brandId1: event.target.value });
    this.setState({ modelId1: "" });

    //console.log("brands1: " + JSON.stringify(this.state.brands)); 

    this.state.brands.map(
      (brand) => (

        event.target.value == brand.brandId
          ? this.setState({ models1: brand.models })
          : console.log("No brand matches")
      )
    );
  };
  onChangeModelId1Handler = (event) => {
    
    this.setState({ modelId1: event.target.value });
    this.setState({ variantId1: "" });

    this.state.models1.map(
      (model) => (

        event.target.value == model.modelId
          ? this.setState({ variants1: model.variants })
          : console.log("No Model Matches")
      )
    );
  };
  onChangeVariantId1Handler = (event) => {
    // alert('variantId1' + event.target.value);

    this.setState({ variantId1: event.target.value });

    this.state.variants1.map(
      (variant) => (

        event.target.value == variant.variantId
          ? this.setState({ variant1: variant })
          : console.log("No variant Matches")
      )
    );
  };
  onChangeBrandId2Handler = (event) => {
    // alert('Hello' + event.target.value);

    this.setState({ brandId2: event.target.value });
    this.setState({ modelId2: "" });

    //console.log("brands1: " + JSON.stringify(this.state.brands));

    this.state.brands.map(
      (brand) => (

        event.target.value == brand.brandId
          ? this.setState({ models2: brand.models })
          : console.log("No brand matches")
      )
    );
  };
  onChangeModelId2Handler = (event) => {
    
    this.setState({ modelId2: event.target.value });
    this.setState({ variantId2: "" });

    this.state.models2.map(
      (model) => (

        event.target.value == model.modelId
          ? this.setState({ variants2: model.variants })
          : console.log("No Model Matches")
      )
    );
  };
  onChangeVariantId2Handler = (event) => {
    // alert('variantId2' + event.target.value);

    this.setState({ variantId2: event.target.value });

    this.state.variants2.map(
      (variant) => (

        event.target.value == variant.variantId
          ? this.setState({ variant2: variant })
          : console.log("No variant Matches")
      )
    );
  };
  onChangeBrandId3Handler = (event) => {
    //alert('Hello' + event.target.value);

    this.setState({ brandId3: event.target.value });
    this.setState({ modelId3: "" });

    //console.log("brands1: " + JSON.stringify(this.state.brands));

    this.state.brands.map(
      (brand) => (

        event.target.value == brand.brandId
          ? this.setState({ models3: brand.models })
          : console.log("No brand matches")
      )
    );
  };
  onChangeModelId3Handler = (event) => {
    
    this.setState({ modelId3: event.target.value });
    this.setState({ variantId3: "" });

    this.state.models3.map(
      (model) => (

        event.target.value == model.modelId
          ? this.setState({ variants3: model.variants })
          : console.log("No Model Matches")
      )
    );
  };
  onChangeVariantId3Handler = (event) => {
    // alert('variantId2' + event.target.value);

    this.setState({variantId3: event.target.value });

    this.state.variants3.map(
      (variant) => (

        event.target.value == variant.variantId
          ? this.setState({ variant3: variant })
          : console.log("No variant Matches")
      )
    );
  };
  // onChangeBrandId2Handler = (event) => {
  //   //alert('Hello' + event.target.value);
  //   console.log("brandId2: " + event.target.value);

  //   this.setState({ brandId2: event.target.value });
  //   this.setState({ modelId2: "" });

  //   console.log("brands2: " + JSON.stringify(this.state.brands));
  // };
  // onChangeBrandId3Handler = (event) => {
  //   //alert('Hello' + event.target.value);
  //   console.log("brandId3: " + event.target.value);

  //   this.setState({ brandId3: event.target.value });
  //   this.setState({ modelId3: "" });
  //   console.log("brands3: " + JSON.stringify(this.state.brands));
  // };
  // onChangeBrandId4Handler = (event) => {
  //   //alert('Hello' + event.target.value);
  //   console.log("brandId4: " + event.target.value);

  //   this.setState({ brandId4: event.target.value });
  //   this.setState({ modelId4: "" });

  //   console.log("brands4: " + JSON.stringify(this.state.brands));
  // };
  onChangeModelIdHandler = (event) => {
    //alert('Hello' + event.target.value);
    console.log("modelId: " + event.target.value);

    this.setState({ modelId: event.target.value });
    console.log("state.modelId: " + this.state.modelId);
    this.retrieveModels(event.target.name, event.target.value);
  };

  onChangeFuelTypeHandler = (event) => {
    // alert('Hello' + event.target.value);
    console.log("fuelType: " + event.target.value);

    this.setState({ fuelType: event.target.value });
    console.log("state.fuelType: " + this.state.fuelType);
    this.retrieveModels(event.target.name, event.target.value);
  };
  onChangeTransmissionHandler = (event) => {
    // alert('Hello' + event.target.value);
    console.log("transmission: " + event.target.value);

    this.setState({ transmission: event.target.value });
    console.log("state.transmission: " + this.state.transmission);
    this.retrieveModels(event.target.name, event.target.value);
  };

  getRequestParams(brandId, modelId, fuelType, transmission, page, pageSize) {
    let params = {};

    if (brandId) {
      params["brandId"] = brandId;
    }
    if (modelId) {
      params["modelId"] = modelId;
    }
    if (fuelType) {
      params["fuelType"] = fuelType;
    }
    if (transmission) {
      params["transmission"] = transmission;
    }
    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  }

  retrieveModels() {
    const { brandId, modelId, fuelType, transmission, page, pageSize } =
      this.state;

    const params = this.getRequestParams(
      brandId,
      modelId,
      fuelType,
      transmission,
      page,
      pageSize
    );

    ModelService.getAll(params)
      .then((response) => {
        const { models, totalPages } = response.data;

        this.setState({
          models: models,
          count: totalPages,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  retrieveModels(name, value) {
    const { brandId, modelId, fuelType, transmission, page, pageSize } =
      this.state;
    let params;

    //alert(name);
    //alert(value);

    switch (name) {
      case "brandId":
        params = this.getRequestParams(
          value,
          modelId,
          fuelType,
          transmission,
          page,
          pageSize
        );
        break;
      case "modelId":
        params = this.getRequestParams(
          brandId,
          value,
          fuelType,
          transmission,
          page,
          pageSize
        );
        break;
      case "fuelType":
        params = this.getRequestParams(
          brandId,
          modelId,
          value,
          transmission,
          page,
          pageSize
        );
        break;
      case "transmission":
        params = this.getRequestParams(
          brandId,
          modelId,
          fuelType,
          value,
          page,
          pageSize
        );
        break;
      default:
        params = this.getRequestParams(
          brandId,
          modelId,
          fuelType,
          transmission,
          page,
          pageSize
        );
    }

    ModelService.getAll(params)
      .then((response) => {
        const { models, totalPages } = response.data;

        this.setState({
          models: models,
          count: totalPages,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  refreshList() {
    this.retrieveModels();
    this.setState({
      currentModel: null,
      currentIndex: -1,
    });
  }

  setActiveModel(model, index) {
    this.setState({
      currentModel: model,
      currentIndex: index,
    });
  }

  removeAllModels() {
    ModelService.deleteAll()
      .then((response) => {
        console.log(response.data);
        this.refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handlePageChange(event, value) {
    this.setState(
      {
        page: value,
      },
      () => {
        this.retrieveModels();
      }
    );
  }

  handlePageSizeChange(event) {
    this.setState(
      {
        pageSize: event.target.value,
        page: 1,
      },
      () => {
        this.retrieveModels();
      }
    );
  }
  
  compareResult = () => {
    // alert("variants"+this.state.variantId1);
    // alert("variants"+this.state.variantId2);
    // alert("variants"+this.state.variantId3);

    this.props.history.push(`/compare-car-result/${this.state.variantId1}/${this.state.variantId2}/${this.state.variantId3}`);


  };
  

  render() {
    const { models, page, count, pageSize } = this.state;
    return (
      <div>
        <FrontTopBarComponent />

        <div class="page-content bg-white">
          {/* <!-- Banner  --> */}
          <div
             class="dlab-bnr-inr style-1 overlay-black-middle"
            style={{
              backgroundImage: "url('images/slider3.jpg')",
              height: "100vh",
              marginTop: "-70px",
              fontSize: "50px",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div class="container">
              <div class="dlab-bnr-inr-entry">
                <h1 class="text-white">Add Cars to Compare</h1>
                <div class="dlab-separator"></div>
              </div>
            </div>
          </div>
          {/* <!-- Banner End --> */}

          <section class="content-inner-2">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="m-b30">
                    <h3 class="m-t0">Compare to choose the right car! </h3>
                    <ul class="used-car-dl-info">
                      <li>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.{" "}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6 m-b30">
                  <div class="compare-box">
                    <div class="icon-box">
                      <svg
                        width="71"
                        height="59"
                        viewBox="0 0 94 78"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M91.2002 22.7304H80.3161L77.291 14.351C74.1823 5.73605 66.7632 0.170654 58.3879 0.170654H35.2999C26.9264 0.170654 19.5055 5.73605 16.3949 14.351L13.3699 22.7304H2.48756C1.06774 22.7304 0.0260468 24.2088 0.371459 25.7336L1.46223 30.5678C1.70402 31.6435 2.57664 32.3988 3.57833 32.3988H7.22697C4.78546 34.7615 3.21293 38.1958 3.21293 42.0672V51.7357C3.21293 54.9827 4.33279 57.9134 6.12166 60.1815V71.0726C6.12166 74.6318 8.72679 77.5182 11.9391 77.5182H17.7566C20.9689 77.5182 23.574 74.6318 23.574 71.0726V64.627H70.1137V71.0726C70.1137 74.6318 72.7189 77.5182 75.9312 77.5182H81.7487C84.961 77.5182 87.5661 74.6318 87.5661 71.0726V60.1815C89.355 57.9154 90.4749 54.9847 90.4749 51.7357V42.0672C90.4749 38.1958 88.9023 34.7615 86.4626 32.3988H90.1113C91.113 32.3988 91.9856 31.6435 92.2274 30.5678L93.3181 25.7336C93.6617 24.2088 92.62 22.7304 91.2002 22.7304ZM27.1972 19.1389C28.5225 15.469 31.7312 13.0619 35.2999 13.0619H58.3879C61.9566 13.0619 65.1653 15.469 66.4905 19.1389L70.1137 29.176H23.574L27.1972 19.1389ZM17.7566 51.6954C14.2661 51.6954 11.9391 49.1252 11.9391 45.2699C11.9391 41.4146 14.2661 38.8444 17.7566 38.8444C21.2471 38.8444 26.4828 44.6274 26.4828 48.4827C26.4828 52.338 21.2471 51.6954 17.7566 51.6954ZM75.9312 51.6954C72.4407 51.6954 67.205 52.338 67.205 48.4827C67.205 44.6274 72.4407 38.8444 75.9312 38.8444C79.4217 38.8444 81.7487 41.4146 81.7487 45.2699C81.7487 49.1252 79.4217 51.6954 75.9312 51.6954Z"
                          fill="url(#paint0_linear3)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear3"
                            x1="22.9458"
                            y1="0.170653"
                            x2="83.851"
                            y2="99.1745"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#EE3131" />
                            <stop offset="1" stop-color="#FF8E8E" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <i class="fas fa-plus plus-ico"></i>
                    </div>
                    <form>
                      <h6 class="m-b15">Add to compare</h6>
                      <div class="input-group m-b20">
                        <select
                          class="form-control sm"
                          name="brandId1"
                          value={this.state.brandId1}
                          aria-label="Default select example"
                          onChange={this.onChangeBrandId1Handler}
                        >
                          <option value="">-Select Brand-</option>
                          {this.state.brands.map((brand) => (
                            <option key={brand.brandId} value={brand.brandId}>
                              {brand.brandName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="input-group m-b20">
                        <select
                          class="form-control sm"
                          name="modelId1"
                          value={this.state.modelId1}
                          aria-label="Default select example"
                          onChange={this.onChangeModelId1Handler}
                        >
                          <option value="">-Select Model-</option>
                          {this.state.models1.map((model) => (
                            <option key={model.modelId} value={model.modelId}>
                              {model.modelName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="input-group">
                      <select
                          class="form-control sm"
                          name="variantId1"
                          value={this.state.variantId1}
                          aria-label="Default select example"
                          onChange={this.onChangeVariantId1Handler}
                        >
                          <option value="">-Select Variant-</option>
                          {this.state.variants1.map((variant) => (
                            <option key={variant.variantId} value={variant.variantId}>
                              {variant.variantName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6 m-b30">
                  <div class="compare-box">
                    <div class="icon-box">
                      <svg
                        width="71"
                        height="59"
                        viewBox="0 0 94 78"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M91.2002 22.7304H80.3161L77.291 14.351C74.1823 5.73605 66.7632 0.170654 58.3879 0.170654H35.2999C26.9264 0.170654 19.5055 5.73605 16.3949 14.351L13.3699 22.7304H2.48756C1.06774 22.7304 0.0260468 24.2088 0.371459 25.7336L1.46223 30.5678C1.70402 31.6435 2.57664 32.3988 3.57833 32.3988H7.22697C4.78546 34.7615 3.21293 38.1958 3.21293 42.0672V51.7357C3.21293 54.9827 4.33279 57.9134 6.12166 60.1815V71.0726C6.12166 74.6318 8.72679 77.5182 11.9391 77.5182H17.7566C20.9689 77.5182 23.574 74.6318 23.574 71.0726V64.627H70.1137V71.0726C70.1137 74.6318 72.7189 77.5182 75.9312 77.5182H81.7487C84.961 77.5182 87.5661 74.6318 87.5661 71.0726V60.1815C89.355 57.9154 90.4749 54.9847 90.4749 51.7357V42.0672C90.4749 38.1958 88.9023 34.7615 86.4626 32.3988H90.1113C91.113 32.3988 91.9856 31.6435 92.2274 30.5678L93.3181 25.7336C93.6617 24.2088 92.62 22.7304 91.2002 22.7304ZM27.1972 19.1389C28.5225 15.469 31.7312 13.0619 35.2999 13.0619H58.3879C61.9566 13.0619 65.1653 15.469 66.4905 19.1389L70.1137 29.176H23.574L27.1972 19.1389ZM17.7566 51.6954C14.2661 51.6954 11.9391 49.1252 11.9391 45.2699C11.9391 41.4146 14.2661 38.8444 17.7566 38.8444C21.2471 38.8444 26.4828 44.6274 26.4828 48.4827C26.4828 52.338 21.2471 51.6954 17.7566 51.6954ZM75.9312 51.6954C72.4407 51.6954 67.205 52.338 67.205 48.4827C67.205 44.6274 72.4407 38.8444 75.9312 38.8444C79.4217 38.8444 81.7487 41.4146 81.7487 45.2699C81.7487 49.1252 79.4217 51.6954 75.9312 51.6954Z"
                          fill="url(#paint0_linear5)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear5"
                            x1="22.9458"
                            y1="0.170653"
                            x2="83.851"
                            y2="99.1745"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#EE3131" />
                            <stop offset="1" stop-color="#FF8E8E" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <i class="fas fa-plus plus-ico"></i>
                    </div>
                    <form>
                      <h6 class="m-b15">Add to compare</h6>
                      <div class="input-group m-b20">
                        <select
                          class="form-control sm"
                          name="brandId2"
                          value={this.state.brandId2}
                          aria-label="Default select example"
                          onChange={this.onChangeBrandId2Handler}
                        >
                          <option value="">Select Brand</option>
                          {this.state.brands.map((brand) => (
                            <option key={brand.brandId} 
                            value={brand.brandId}>
                              {brand.brandName}{" "}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="input-group m-b20">
                      <select
                          class="form-control sm"
                          name="modelId2"
                          value={this.state.modelId2}
                          aria-label="Default select example"
                          onChange={this.onChangeModelId2Handler}
                        >
                          <option value="">-Select Model-</option>
                          {this.state.models2.map((model) => (
                            <option key={model.modelId} value={model.modelId}>
                              {model.modelName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="input-group">
                      <select
                          class="form-control sm"
                          name="variantId2"
                          value={this.state.variantId2}
                          aria-label="Default select example"
                          onChange={this.onChangeVariantId2Handler}
                        >
                          <option value="">-Select Variant-</option>
                          {this.state.variants2.map((variant) => (
                            <option key={variant.variantId} value={variant.variantId}>
                              {variant.variantName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6 m-b30">
                  <div class="compare-box">
                    <div class="icon-box">
                      <svg
                        width="71"
                        height="59"
                        viewBox="0 0 94 78"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M91.2002 22.7304H80.3161L77.291 14.351C74.1823 5.73605 66.7632 0.170654 58.3879 0.170654H35.2999C26.9264 0.170654 19.5055 5.73605 16.3949 14.351L13.3699 22.7304H2.48756C1.06774 22.7304 0.0260468 24.2088 0.371459 25.7336L1.46223 30.5678C1.70402 31.6435 2.57664 32.3988 3.57833 32.3988H7.22697C4.78546 34.7615 3.21293 38.1958 3.21293 42.0672V51.7357C3.21293 54.9827 4.33279 57.9134 6.12166 60.1815V71.0726C6.12166 74.6318 8.72679 77.5182 11.9391 77.5182H17.7566C20.9689 77.5182 23.574 74.6318 23.574 71.0726V64.627H70.1137V71.0726C70.1137 74.6318 72.7189 77.5182 75.9312 77.5182H81.7487C84.961 77.5182 87.5661 74.6318 87.5661 71.0726V60.1815C89.355 57.9154 90.4749 54.9847 90.4749 51.7357V42.0672C90.4749 38.1958 88.9023 34.7615 86.4626 32.3988H90.1113C91.113 32.3988 91.9856 31.6435 92.2274 30.5678L93.3181 25.7336C93.6617 24.2088 92.62 22.7304 91.2002 22.7304ZM27.1972 19.1389C28.5225 15.469 31.7312 13.0619 35.2999 13.0619H58.3879C61.9566 13.0619 65.1653 15.469 66.4905 19.1389L70.1137 29.176H23.574L27.1972 19.1389ZM17.7566 51.6954C14.2661 51.6954 11.9391 49.1252 11.9391 45.2699C11.9391 41.4146 14.2661 38.8444 17.7566 38.8444C21.2471 38.8444 26.4828 44.6274 26.4828 48.4827C26.4828 52.338 21.2471 51.6954 17.7566 51.6954ZM75.9312 51.6954C72.4407 51.6954 67.205 52.338 67.205 48.4827C67.205 44.6274 72.4407 38.8444 75.9312 38.8444C79.4217 38.8444 81.7487 41.4146 81.7487 45.2699C81.7487 49.1252 79.4217 51.6954 75.9312 51.6954Z"
                          fill="url(#paint0_linear6)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear6"
                            x1="22.9458"
                            y1="0.170653"
                            x2="83.851"
                            y2="99.1745"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#EE3131" />
                            <stop offset="1" stop-color="#FF8E8E" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <i class="fas fa-plus plus-ico"></i>
                    </div>
                    <form>
                      <h6 class="m-b15">Add to compare</h6>
                      <div class="input-group m-b20">
                        <select
                          class="form-control sm"
                          name="brandId3"
                          value={this.state.brandId3}
                          aria-label="Default select example"
                          onChange={this.onChangeBrandId3Handler}
                        >
                          <option value="">Select Brand</option>
                          {this.state.brands.map((brand) => (
                            <option key={brand.brandId} value={brand.brandId}>
                              {brand.brandName}{" "}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="input-group m-b20">
                      <select
                          class="form-control sm"
                          name="modelId3"
                          value={this.state.modelId3}
                          aria-label="Default select example"
                          onChange={this.onChangeModelId3Handler}
                        >
                          <option value="">-Select Model-</option>
                          {this.state.models3.map((model) => (
                            <option key={model.modelId} value={model.modelId}>
                              {model.modelName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="input-group">
                      <select
                          class="form-control sm"
                          name="variantId3"
                          value={this.state.variantId3}
                          aria-label="Default select example"
                          onChange={this.onChangeVariantId3Handler}
                        >
                          <option value="">-Select Variant-</option>
                          {this.state.variants3.map((variant) => (
                            <option key={variant.variantId} value={variant.variantId}>
                              {variant.variantName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
       
                {/* <div class="col-xl-3 col-md-6 m-b30">
                  <div class="compare-box">
                    <div class="icon-box">
                      <svg
                        width="71"
                        height="59"
                        viewBox="0 0 94 78"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M91.2002 22.7304H80.3161L77.291 14.351C74.1823 5.73605 66.7632 0.170654 58.3879 0.170654H35.2999C26.9264 0.170654 19.5055 5.73605 16.3949 14.351L13.3699 22.7304H2.48756C1.06774 22.7304 0.0260468 24.2088 0.371459 25.7336L1.46223 30.5678C1.70402 31.6435 2.57664 32.3988 3.57833 32.3988H7.22697C4.78546 34.7615 3.21293 38.1958 3.21293 42.0672V51.7357C3.21293 54.9827 4.33279 57.9134 6.12166 60.1815V71.0726C6.12166 74.6318 8.72679 77.5182 11.9391 77.5182H17.7566C20.9689 77.5182 23.574 74.6318 23.574 71.0726V64.627H70.1137V71.0726C70.1137 74.6318 72.7189 77.5182 75.9312 77.5182H81.7487C84.961 77.5182 87.5661 74.6318 87.5661 71.0726V60.1815C89.355 57.9154 90.4749 54.9847 90.4749 51.7357V42.0672C90.4749 38.1958 88.9023 34.7615 86.4626 32.3988H90.1113C91.113 32.3988 91.9856 31.6435 92.2274 30.5678L93.3181 25.7336C93.6617 24.2088 92.62 22.7304 91.2002 22.7304ZM27.1972 19.1389C28.5225 15.469 31.7312 13.0619 35.2999 13.0619H58.3879C61.9566 13.0619 65.1653 15.469 66.4905 19.1389L70.1137 29.176H23.574L27.1972 19.1389ZM17.7566 51.6954C14.2661 51.6954 11.9391 49.1252 11.9391 45.2699C11.9391 41.4146 14.2661 38.8444 17.7566 38.8444C21.2471 38.8444 26.4828 44.6274 26.4828 48.4827C26.4828 52.338 21.2471 51.6954 17.7566 51.6954ZM75.9312 51.6954C72.4407 51.6954 67.205 52.338 67.205 48.4827C67.205 44.6274 72.4407 38.8444 75.9312 38.8444C79.4217 38.8444 81.7487 41.4146 81.7487 45.2699C81.7487 49.1252 79.4217 51.6954 75.9312 51.6954Z"
                          fill="url(#paint0_linear7)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear7"
                            x1="22.9458"
                            y1="0.170653"
                            x2="83.851"
                            y2="99.1745"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#EE3131" />
                            <stop offset="1" stop-color="#FF8E8E" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <i class="fas fa-plus plus-ico"></i>
                    </div>
                    <form>
                      <h6 class="m-b15">Add to compare</h6>
                      <div class="input-group m-b20">
                        <select
                          class="form-control sm"
                          name="brandId4"
                          value={this.state.brandId4}
                          aria-label="Default select example"
                          onChange={this.onChangeBrandId4Handler}
                        >
                          <option value="">Select Brand</option>
                          {this.state.brands.map((brand) => (
                            <option key={brand.brandId4} value={brand.brandId4}>
                              {brand.brandName}{" "}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="input-group m-b20">
                        <select class="form-control sm">
                          <option>-Select Model-</option>
                          <option>Creta</option>
                          <option>Elantra</option>
                          <option>EON</option>
                          <option>Grand i10</option>
                        </select>
                      </div>
                      <div class="input-group">
                        <select class="form-control sm">
                          <option>-Select Variant-</option>
                          <option>Creta</option>
                          <option>Elantra</option>
                          <option>EON</option>
                          <option>Grand i10</option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div> */}
                <div class="col-12 text-center">
                  <a onClick={this.compareResult} class="btn btn-primary">
                    Compare Now
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section class="content-inner-2">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="section-head row">
                    <div class="col-sm-8">
                      <h3 class="title">Similar Car Comparisons</h3>
                    </div>
                    <div class="col-sm-4 text-sm-end">
                      <div class="portfolio-pagination d-inline-block">
                        <div class="btn-prev swiper-button-prev2">
                          <i class="las la-arrow-left"></i>
                        </div>
                        <div class="btn-next swiper-button-next2">
                          <i class="las la-arrow-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-container similar-slider lightgallery">
                    <div class="swiper-wrapper">
                      {models &&
                        models.map((model, index1) => (
                          <div class="swiper-slide">
                            <div class="car-list-box border shadow-none">
                              <div class="media-box" style={{height:"220px"}}>
                                <img
                                  src={
                                    "https://api.richgoldshine.com/bsecars/api/v1/models_get_base_image/" +
                                    model.modelId
                                  }
                                />
                                <div class="overlay-bx">
                                  <span
                                    data-exthumbimage="images/product/grid/pic1.jpg"
                                    data-src="images/product/grid/pic1.jpg"
                                    class="view-btn lightimg"
                                  >
                                    <svg
                                      width="75"
                                      height="74"
                                      viewBox="0 0 75 74"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M44.5334 27.7473V32.3718C44.5334 33.3257 43.7424 34.106 42.7755 34.106H34.572V42.199C34.572 43.1528 33.7809 43.9332 32.8141 43.9332H28.1264C27.1595 43.9332 26.3685 43.1528 26.3685 42.199V34.106H18.1649C17.1981 34.106 16.4071 33.3257 16.4071 32.3718V27.7473C16.4071 26.7935 17.1981 26.0131 18.1649 26.0131H26.3685V17.9201C26.3685 16.9663 27.1595 16.1859 28.1264 16.1859H32.8141C33.7809 16.1859 34.572 16.9663 34.572 17.9201V26.0131H42.7755C43.7424 26.0131 44.5334 26.7935 44.5334 27.7473ZM73.9782 68.8913L69.8325 72.9812C68.4555 74.3396 66.2288 74.3396 64.8664 72.9812L50.2466 58.5728C49.5874 57.9225 49.2212 57.0409 49.2212 56.116V53.7604C44.05 57.749 37.5458 60.1191 30.4702 60.1191C13.6384 60.1191 0 46.6646 0 30.0596C0 13.4545 13.6384 0 30.4702 0C47.3021 0 60.9405 13.4545 60.9405 30.0596C60.9405 37.0397 58.538 43.4563 54.4949 48.5578H56.8827C57.8202 48.5578 58.7138 48.9191 59.373 49.5694L73.9782 63.9777C75.3406 65.3362 75.3406 67.5329 73.9782 68.8913ZM50.3931 30.0596C50.3931 19.1919 41.4864 10.4052 30.4702 10.4052C19.4541 10.4052 10.5474 19.1919 10.5474 30.0596C10.5474 40.9273 19.4541 49.7139 30.4702 49.7139C41.4864 49.7139 50.3931 40.9273 50.3931 30.0596Z"
                                        fill="white"
                                        fill-opacity="0.66"
                                      />
                                    </svg>
                                  </span>
                                </div>
                              </div>
                              <div class="list-info">
                                <h4 class="title mb-0">
                                  <a
                                    onClick={() =>
                                      this.displayModel(model.modelId)
                                    }
                                    data-splitting
                                    class="text-Black"
                                  >
                                    {model.brand.brandName} - {model.modelName}
                                  </a>
                                </h4>
                                <div class="car-type">
                                  {" "}
                                  {model.brand.brandName} - {model.modelName}
                                </div>
                                <span class="badge m-b30">$34,500</span>
                                <div class="feature-list" style={{height:"50px"}}>
                                  <div>
                                    <label>Transmissions</label>
                                    <p class="value">
                                      {model.transmissions.map(
                                        (transmission) => (
                                          <span key={transmission}>
                                            {transmission} |{" "}
                                          </span>
                                        )
                                      )}
                                    </p>
                                  </div>
                                  <div>
                                    <label>Fuel</label>
                                    <p class="value">
                                      {model.fuelTypes.map((fuelType) => (
                                        <span key={fuelType}>
                                          {fuelType} |{" "}
                                        </span>
                                      ))}
                                    </p>
                                  </div>
                                  {/* <div>
                                    <label>Passenger</label>
                                    <p class="value">5 Person</p>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="content-inner">
            <div class="container">
              <div class="row call-to-action-bx">
                <div class="col-xl-5 col-lg-6 me-auto">
                  <div class="section-head">
                    <h2 class="title text-white">
                      Have any question about us?
                    </h2>
                  </div>
                  <a href="tel:+9180073 58007" class="btn btn-white me-3 mb-2">
                    <i class="fas fa-phone-volume me-sm-3 me-0 shake"></i>
                    <span class="d-sm-inline-block d-none">
                      +91 80073 58007
                    </span>
                  </a>
                  <a
                    href="contact-us"
                    class="btn btn-outline-white effect-1  mb-2"
                  >
                    <span>Contact Us</span>
                  </a>
                </div>
                <div class="col-lg-6">
                  <div class="media-box">
                    <img src="images/carrrrrrrr.jpg" class="main-img" alt="" />
                    <img
                      src="images/pattern/pattern7.png"
                      class="pt-img move-1"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <FrontEndFooterComponent />
      </div>
    );
  }
}

export default CompareCarComponent;
