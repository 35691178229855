import React, { Component } from 'react'
import FooterComponent from './FooterComponent';
import VariantService from '../services/VariantService';
import ModelService from '../services/ModelService';
import UploadFilesService from "../services/UploadFilesService";

import CustomerService from "../services/CustomerService";
import Circle from '@uiw/react-color-circle';
import { } from "@fortawesome/free-solid-svg-icons";
import CurrencyFormat from 'react-currency-format';


const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};


class DisplayVariantComponent95 extends Component {
  constructor(props) {
    super(props)

    this.state = {

      variantId: '',
      id: this.props.match.params.id,
      model: {},
      brand: {},
      variant: {},
      variants: [],
      specifications: [],
      features: [],
      hex: '',
      colors: [],
      map1: [],
      variantNameStr: '',
      customerName: '', customerNameVal:'',
      customerEmailId: '',
      customerMoblieNumber: '', customerMoblieNumberVal:'',
      customerEmail: '',  customerEmailVal: '', 
      onRoadPrice: 0.0,
      imageInfos: [],
    }

    this.saveOrUpdateCustomer = this.saveOrUpdateCustomer.bind(this);
    this.changeCustomerNameHandler = this.changeCustomerNameHandler.bind(this);
    this.changecustomerNumberHandler = this.changecustomerNumberHandler.bind(this);
    this.changeCustomerEmailHandler = this.changeCustomerEmailHandler.bind(this);


  }

  componentDidMount() {
    ModelService.getModelById(this.state.id).then(res => {
      this.setState({ model: res.data });
      this.setState({ brand: res.data.brand });
      this.setState({ variants: res.data.variants });
      this.setState({ variant: res.data.variants[0] });
      this.setState({ specifications: res.data.variants[0].specificationValues });
      this.setState({ features: res.data.variants[0].featureValues });
      this.setState({ onRoadPrice: this.state.variant.exShowroom + this.state.variant.regAmount + this.state.variant.insPayment + this.state.variant.autoCard + this.state.variant.fastTag - this.state.variant.discount });
    })

    UploadFilesService.getFiles(this.state.id).then((response) => {
      // alert("in upload " + response);
      this.setState({
        imageInfos: response.data,
      });
    });
  }


  saveOrUpdateCustomer = (e) => {
    e.preventDefault();
    if (this.state.customerName === '') {
      this.setState({customerNameVal: 'Invalid!, Please enter  customer name'})
      return false;
    } 
  
    if (this.state.customerEmail === '') {
    this.setState({customerEmailVal: 'Invalid!, Please enter email id'})
    return false;
    } 

    if (this.state.customerMoblieNumber === '') {
      this.setState({customerMoblieNumberVal: 'Invalid!, Please enter mobile number'})
      return false;
      } 
    let customer = { name: this.state.customerName, mobile: this.state.customerMoblieNumber, email: this.state.customerEmail,
      inquiry: this.state.brand.brandName +"-"+ this.state.model.modelName +"-"+this.state.variant.variantName, 
      brandId: this.state.brand.brandId};
    console.log('customer => ' + JSON.stringify(customer));

    CustomerService.createCustomer(customer).then(res => {
      window.location.reload(false);
    });

  }

  changeCustomerNameHandler = (event) => {
    this.setState({ customerName: event.target.value });
  }

  changecustomerNumberHandler = (event) => {
    this.setState({ customerMoblieNumber: event.target.value });
  }

  changeCustomerEmailHandler = (event) => {
    this.setState({ customerEmail: event.target.value });
  }


  changeVariantIdHandler = (event) => {
    this.setState({ variantId: event.target.value });
    console.log("variantId", event.target.value);
    if (event.target.value !== "") {
      VariantService.getVariantById(event.target.value).then(res => {
        console.log(res.data);
        this.setState({ model: res.data.model });
        this.setState({ brand: res.data.model.brand });
        this.setState({ variant: res.data });
        this.setState({ specifications: res.data.specificationValues });
        this.setState({ features: res.data.featureValues });
        this.setState({ onRoadPrice: this.state.variant.exShowroom + this.state.variant.regAmount + this.state.variant.insPayment + this.state.variant.autoCard + this.state.variant.fastTag - this.state.variant.discount });
        this.setState({ colors: this.state.variant.colors ? this.state.variant.colors.map(color => color.colorCode) : [] });

        console.log(this.state.colors);
      })
    }
  }

  render() {
    const { imageInfos } = this.state;


    return (
      <div>
        <header>


          <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="/"><img src='/images/logo.jpeg' width="70px" ></img></a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
              <ul class="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" href="/about-us">About Us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact-us">Contact Us</a>
                </li>
              </ul>


              <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                  <a class="nav-link" href="/login">Login</a>

                  {/* <Link to="/login" className="nav-link"> Login </Link>  */}

                </li>
                {/* <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="http://bsecars.com/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">SignUp</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" href="/login">Login</a>
                  </div>
                </li> */}
              </ul>
            </div>
          </nav>

        </header>
        <div class="container">
          <div class="row">
            <div class="col-md-6 offset-md-1">
              <div class="card" style={{ margin: 10 }}>

                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                  <div class="carousel-inner">
                
                    {imageInfos &&
                      imageInfos.map((img, index) => (

                        
                        <div className={index === 0 ? "carousel-item active" : "carousel-item" } key={index}>

                            <img src={`https://api.richgoldshine.com/bsecars/api/v1/files/` + img.modelImageName}
                            alt={img.modelImageName} width="100%"  height="300px"/>

                        </div>
                      ))}
                  </div>
                  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>

                <div class="card-body">
                  <h4 class="card-title"> Car Details:</h4>
                  <div>
                    {this.state.brand.brandName}  -  {this.state.model.modelName} {this.state.variant.variantName}
                    <div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="card" style={{ margin: 10 }}>
                <div class="card-body">
                  <h4 class="card-title"> Car Highlights:</h4>
                  <hr></hr>
                  <div class="row">
                    <div class="col-md-4">
                      <img src="/images/car-specification-icon/fueltype.png" alt="..." /> &nbsp; {this.state.variant.fuelType}

                    </div>

                    <div class="col-md-4">
                      <img src="/images/car-specification-icon/enginecc.png" alt="..." /> &nbsp;

                      {this.state.specifications.map(specificationValue => (
                        <label>{specificationValue.specification.specificationName == "Engine" ? specificationValue.specificationValueName + " " + specificationValue.specificationUnit : ""}  </label>
                      ))}
                    </div>

                    <div class="col-md-4">
                      <img src="/images/car-specification-icon/transmission.png" alt="..." /> &nbsp; {this.state.variant.transmission}
                    </div>
                  </div>
                  <hr></hr>

                  <div class="row">
                    <div class="col-md-4">
                      <img src="/images/car-specification-icon/power.png" alt="..." /> &nbsp; {this.state.specifications.map(specificationValue => (
                        <label>{specificationValue.specification.specificationName == "Max Power" ? specificationValue.specificationValueName + " " + specificationValue.specificationUnit : ""}  </label>
                      ))}

                    </div>

                    <div class="col-md-4">
                      <img src="/images/car-specification-icon/milege.png" alt="..." /> &nbsp; {this.state.specifications.map(specificationValue => (
                        <label>{specificationValue.specification.specificationName == "Mileage" ? specificationValue.specificationValueName + " " + specificationValue.specificationUnit : ""}  </label>
                      ))}
                    </div>

                    <div class="col-md-4">
                      <img src="/images/car-specification-icon/carseat.png" alt="..." /> &nbsp;  {this.state.specifications.map(specificationValue => (
                        <label>{specificationValue.specification.specificationName == "Seating Capacity" ? specificationValue.specificationValueName + " " + specificationValue.specificationUnit : ""}  </label>
                      ))}
                    </div>
                  </div>

                </div>
              </div>
            </div>
            {/*Side div */}
            <div class="col-md-4">
              <div class="card" style={{ margin: 10 }}>

                <div class="card-body">
                  <form>
                    <div className="form-group">
                      <select class="form-select col-12" name="variantId" aria-label="Default select example" onChange={this.changeVariantIdHandler} >
                        {this.state.variants.map(currentVariant =>
                          <option value={currentVariant.variantId}>{currentVariant.variantName} </option>)
                        }
                      </select>
                    </div>
                  </form>
                  <h6 class="card-title">
                    <div>
                      Colors:
                      <Circle
                        colors={this.state.colors}
                        color={this.state.hex}
                      />
                    </div>
                  </h6>

                  <hr></hr>

                  <button type="button" className="btn btn-primary btn-block" data-toggle="modal" data-target="#enquiryModal">
                    Enquire Now
                  </button>

                  {/* Modal Start */}
                  <div className="modal fade" id="enquiryModal" tabindex="-1" data-keyboard="false" data-backdrop="static">
                    <div className="modal-dialog modal-sm">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Guranteed Delivery !!!</h5>
                          <button className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className="form-group">
                              <label for="inputUserName" req>Name</label>
                              <input type="text" placeholder="Customer Name" name="customerName" className="form-control" 
                                value={this.state.customerName} onChange={this.changeCustomerNameHandler}    required/>
                                <p className="text-danger"> {this.state.customerNameVal }</p>
                            </div>
                            <div className="form-group">
                              <label for="inputEmail">Email Id</label>
                              <input type="email" placeholder="Email" name="Email" className="form-control"
                                value={this.state.customerEmail} onChange={this.changeCustomerEmailHandler}   required />
                                <p className="text-danger"> {this.state.customerEmailVal }</p>
                            </div>
                            <div className="form-group">
                              <label for="inputMobile">Mobile</label>
                              <input type="text" placeholder="Customer Number" name="customerNumber" className="form-control"
                                value={this.state.customerMoblieNumber} onChange={this.changecustomerNumberHandler}   required />
                                <p className="text-danger"> {this.state.customerMoblieNumberVal }</p>
                            </div>

                          </form>
                        </div>
                        <div className="modal-footer">
                          <button className="btn btn-primary"  onClick={this.saveOrUpdateCustomer}>Send Enquiry</button>

                          <button id="btnHideModal" type="button" className="btn btn-primary" data-dismiss="modal">Cancel</button></div>
                      </div>
                    </div>
                  </div>
                  {/* Modal End */}

                </div>
              </div>

              <div class="card" style={{ margin: 10 }}>

                <div class="card-body">
                  <h4>Pricing Breakup</h4>
                  <hr></hr>
                  {this.state.variant.exShowroom > 0  && (
                  <div className="row  d-flex justify-content-between">
                    <label > Ex-Showroom: </label>

                    <div ><CurrencyFormat value={this.state.variant.exShowroom} displayType={'text'} thousandSeparator={true} prefix={'\u20B9'} renderText={value => <div>{value}</div>} />
                    </div>
                  </div>
                    )}
                  {this.state.variant.regAmount > 0  && (
                  <div className="row  d-flex justify-content-between">
                    <label> Registration Amount: </label>
                    <div className="pull-left"><CurrencyFormat value={this.state.variant.regAmount} displayType={'text'} thousandSeparator={true} prefix={'\u20B9'} renderText={value => <div>{value}</div>} />
                    </div>
                  </div>
                  )}
                  {this.state.variant.insPayment > 0  && (
                  <div className="row  d-flex justify-content-between">
                    <label> Insurance Payment: </label>
                    <div className="pull-left"><CurrencyFormat value={this.state.variant.insPayment} displayType={'text'} thousandSeparator={true} prefix={'\u20B9'} renderText={value => <div>{value}</div>} />
                    </div>
                  </div>
                  )}
                  {this.state.variant.tcs > 0  && (
                  <div className="row  d-flex justify-content-between">
                    <label> TCS: </label>
                    <div><CurrencyFormat value={this.state.variant.tcs} displayType={'text'} thousandSeparator={true} prefix={'\u20B9'} renderText={value => <div>{value}</div>} />
                    </div>
                  </div>
                  )}
                  {this.state.variant.autoCard > 0  && (
                  <div className="row  d-flex justify-content-between">
                    <label> Auto Card: </label>
                    <div><CurrencyFormat value={this.state.variant.autoCard} displayType={'text'} thousandSeparator={true} prefix={'\u20B9'} renderText={value => <div>{value}</div>} />
                    </div>
                  </div>
                  )}
                  {this.state.variant.fastTag > 0  && (
                  <div className="row  d-flex justify-content-between">
                    <label> Fast Tag: </label>
                    <div ><CurrencyFormat value={this.state.variant.fastTag} displayType={'text'} thousandSeparator={true} prefix={'\u20B9'} renderText={value => <div>{value}</div>} />
                    </div>
                  </div>
                  )}
                  {this.state.variant.discount > 0  && (
                  <div className="row  d-flex justify-content-between">
                    <label> Discount: </label>
                    <div className="pull-left"><CurrencyFormat value={this.state.variant.discount} displayType={'text'} thousandSeparator={true} prefix={'\u20B9'} renderText={value => <div>{value}</div>} />
                    </div>
                  </div>
)}
                  <hr></hr>
                  <div className="row  d-flex justify-content-between">
                    <label> On Road Price: </label>
                    <div className="pull-left"><CurrencyFormat value={this.state.onRoadPrice} displayType={'text'} thousandSeparator={true} prefix={'\u20B9'} renderText={value => <div>{value}</div>} />
                    </div>
                  </div>
                  <button type="button" className="btn btn-primary btn-block" data-toggle="modal" data-target="#enquiryModal">
                    Enquire Now
                  </button>
                </div>
              </div>
            </div>
            {/*end side div*/}
          </div>



          <div class="row">
            <div class="col-md-6 offset-md-1">
              <div class="card" style={{ margin: 10 }}>
                <div class="card-body">
                  <div class="container mt-4">
                    <h3>Car Details - Specifications &amp; Features</h3>
                    <br />
                    {/* <!-- Nav tabs --> */}
                    <ul class="nav nav-tabs">
                      <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#home">Specifications</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#menu1">Features</a>
                      </li>
                    </ul>

                    {/* <!-- Tab panes -->! */}
                    <div class="tab-content">
                      <div id="home" class="container tab-pane active "><br />
                        <p >
                          <ul >
                            {this.state.specifications.map(specificationValue => (
                              <li  style={{ listStyleType: "none" }}>
                                <div class="row">
                                  <div class="col-md-4"><p class="text-left" > {specificationValue.specification.specificationName}  : </p></div>
                                  <div class="col-md-6 offset-md-2"><p class="text-right"> {specificationValue.specificationValueName}  {specificationValue.specificationUnit} </p>
                                  </div>
                                </div>
                              </li>
                            ))}

                          </ul>
                        </p>
                      </div>
                      <div id="menu1" class="container tab-pane fade"><br />

                        <ul>
                          {this.state.features.map(featureValue => (
                            <li  style={{ listStyleType: "none" }}>
                              <div class="row">
                                <div class="col-md-4"><p class="text-left">  {featureValue.feature.featureName}  : </p></div>
                                <div class="col-md-6 offset-md-2"><p class="text-right"> {featureValue.featureValueName} </p>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </div>
    )
  }
}

export default DisplayVariantComponent95
