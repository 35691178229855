import React, { Component } from 'react'
import ModelService from '../services/ModelService';
import BrandService from '../services/BrandService';

import SideBarComponent from './SideBarComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';



class CreateModelComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // step 2
            id: this.props.match.params.id,
            modelName: '',
            brandId: 0,
            brands: [],
            message: '', brandVal: '', modelNameVal: ''

        }
        this.changeModelNameHandler = this.changeModelNameHandler.bind(this);

        this.saveOrUpdateModel = this.saveOrUpdateModel.bind(this);

    }


    // step 3
    componentDidMount() {
        BrandService.getBrands().then((res) => {
            this.setState({ brands: res.data });
        });

        // step 4
        if (this.state.id === '0') {
            return
        } else {
            ModelService.getModelById(this.state.id).then((res) => {
                let model = res.data;
                this.setState({
                    brandId:model.brand.brandId,
                    modelName: model.modelName,
                });
            });
        }
    }
    saveOrUpdateModel = (e) => {
        e.preventDefault();

         // validations
        if (this.state.brandId === 0) {
            this.setState({brandVal: 'Invalid!, Please select brand name'})
            return false;
        } 
        if (this.state.modelName === '') {
            this.setState({modelNameVal: 'Invalid!, Please enter model name'})
            return false;
        } 

        let model = { modelId: this.state.id, modelName: this.state.modelName, brand: { brandId: this.state.brandId } };
        console.log('model => ' + JSON.stringify(model));

        // step 5
        if (this.state.id === '0') {
            ModelService.createModel(model).then(res => {
                this.props.history.push('/models');
            });
        } else {
            ModelService.updateModel(model, this.state.id).then(res => {
                this.props.history.push('/models');
            });
        }
    }

    changeModelNameHandler = (event) => {
        this.setState({ modelName: event.target.value });
    }

    changeBrandIdHandler = (event) => {
        this.setState({ brandId: event.target.value });
    }



    cancel() {
        this.props.history.push('/models');
    }

    getTitle() {
        if (this.state.id === '0') {
            return <h6 className="m-0 font-weight-bold text-primary">Add Model</h6>
        } else {
            return <h6 className="m-0 font-weight-bold text-primary">Update Model</h6>
        }
    }
    render() {
        return (
            // <!-- Page Wrapper -->
            <div id="wrapper">

                <SideBarComponent />

                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" class="d-flex flex-column">

                    {/* <!-- Main Content --> */}
                    <div id="content">

                        <TopBarComponent />


                        {/* <!-- Begin Page Content --> */}
                        <div class="container-fluid">

                            {/* <!-- Page Heading --> */}
                            {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Model</h1>
                
                </div> */}

                            {/* <!-- Content Row --> */}
                            {/* <!-- Content Row --> */}
                            <div class="row">

                                <div class="col-xl-12 col-lg-12">

                                    {/* <!-- Area Chart --> */}
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                        <a class="btn btn-sm  btn-primary float-right   mr-3" href="/models" role="button">List Model</a>

                                            {this.getTitle()}
                                        </div>
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="card col-md-6 offset-md-3 offset-md-3">

                                                    <div className="card-body">
                                                        <form>

                                                            <div className="form-group">

                                                                <select class="form-select col-12" name="brandId" aria-label="Default select example" onChange={this.changeBrandIdHandler}>
                                                                <option value="">Select Brand</option>

                                                                    {this.state.brands.map(brand =>
                                                                        <option selected={this.state.brandId == brand.brandId} value={brand.brandId}>{brand.brandName}</option>)
                                                                    }
                                                                </select>
                                                                <p className="text-danger"> {this.state.brandVal }</p>

                                                            </div>
                                                            <div className="form-group">
                                                                <label> Model Name: </label>
                                                                <input placeholder="Model Name" name="modelName" className="form-control"
                                                                    value={this.state.modelName} onChange={this.changeModelNameHandler} />
                                                                <p className="text-danger"> {this.state.modelNameVal }</p>

                                                            </div>
                                                       


                                                            <button className="btn btn-success" onClick={this.saveOrUpdateModel}>Save</button>
                                                            <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Cancel</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />



                                        </div>
                                    </div>



                                </div>


                            </div>

                            {/* <!-- Content Row --> */}


                            {/* <!-- Content Row --> */}


                        </div>
                        {/* <!-- /.container-fluid --> */}

                    </div>
                    {/* <!-- End of Main Content --> */}
                    <BackEndFooterComponent />

                </div>
                {/* <!-- End of Content Wrapper --> */}

            </div>
            // <!-- End of Page Wrapper --> 


        )
    }
}

export default CreateModelComponent
