import React from "react";

import "./App.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ListEmployeeComponent from "./components/ListEmployeeComponent";
import CreateEmployeeComponent from "./components/CreateEmployeeComponent";
import UpdateEmployeeComponent from "./components/UpdateEmployeeComponent";
import ViewEmployeeComponent from "./components/ViewEmployeeComponent";
import ListCustomerComponent from "./components/ListCustomerComponent";
import CreateCustomerComponent from "./components/CreateCustomerComponent";
import ViewCustomerComponent from "./components/ViewCustomerComponent";
import ListProductComponent from "./components/ListProductComponent";
import CreateProductComponent from "./components/CreateProductComponent";
import ViewProductComponent from "./components/ViewProductComponent";
import ListHistoryComponent from "./components/ListHistoryComponent";
import ViewHistoryComponent from "./components/ViewHistoryComponent";
import ListRentalInformationComponent from "./components/ListRentalInformationComponent";
import CreateRentalInformationComponent from "./components/CreateRentalInformationComponent";
import ViewRentalInformationComponent from "./components/ViewRentalInformationComponent";
import LoginComponent from "./components/LoginComponent";
// import LoginDealerComponent from './components/LoginDealerComponent';

import CarListingComponent from "./components/CarListingComponent";
import IndexComponent from "./components/IndexComponent";

import RegisterComponent from "./components/RegisterComponent";
import DashboardComponent from "./components/DashboardComponent";
import CreateBrandComponent from "./components/CreateBrandComponent";
import ListBrandComponent from "./components/ListBrandComponent";
import CreateModelComponent from "./components/CreateModelComponent";
import ListModelComponent from "./components/ListModelComponent";
import ViewModelComponent from "./components/ViewModelComponent";
import ViewBrandComponent from "./components/ViewBrandComponent";
import CreateDealerComponent from "./components/CreateDealerComponent";
import ListDealerComponent from "./components/ListDealerComponent";
import ViewDealerComponent from "./components/ViewDealerComponent";
import CreateCityComponent from "./components/CreateCityComponent";
import ListCityComponent from "./components/ListCityComponent";
import ViewcityComponent from "./components/ViewCityComponent";
import ViewStateComponent from "./components/ViewStateComponent";
import ListStateComponent from "./components/ListStateComponent";
import CreateStateComponent from "./components/CreateStateComponent";
import ViewVariantComponent from "./components/ViewVariantComponent";
import ListVariantComponent from "./components/ListVariantComponent";
import CreateVariantComponent from "./components/CreateVariantComponent";
import ListFeatureComponent from "./components/ListFeatureComponent";
import ViewFeatureComponent from "./components/ViewFeatureComponent";
import CreateFeatureComponent from "./components/CreateFeatureComponent";
import CreateSpecificationComponent from "./components/CreateSpecificationComponent";
import ListSpecificationComponent from "./components/ListSpecificationComponent";
import ViewSpecificationComponent from "./components/ViewSpecificationComponent";
import ModelComponent from "./components/ModelComponent";
import VariantComponent from "./components/VariantComponent";
import DisplayVariantComponent from "./components/DisplayVariantComponent";
import DisplayVariantComponentNew from "./components/DisplayVariantComponentNew";
 import DisplayVComponent from "./components/DisplayVComponent";

 import ListUserComponent from "./components/ListUserComponent";
 import ViewUserComponent from "./components/ViewUserComponent";

import UploadFiles from "./components/UploadFiles";

import ListColorComponent from "./components/ListColorComponent";
import ViewColorComponent from "./components/ViewColorComponent";
import CreateColorComponent from "./components/CreateColorComponent";
import ViewExecutiveComponent from "./components/ViewExecutiveComponent";
import ListExecutiveComponent from "./components/ListExecutiveComponent";
import CreateExecutiveComponent from "./components/CreateExecutiveComponent";
// import ImageUploadComponent from './components/ImageUploadComponent';
import CreateFeatureValueComponent from "./components/CreateFeatureValueComponent";
import CreateSpecificationValueComponent from "./components/CreateSpecificationValueComponent";
import AboutUsComponent from "./components/AboutUsComponent";
import ContactComponent from "./components/ContactComponent";
// import ConComponent from "./components/ConComponent";

import CompareCarComponent from "./components/CompareCarComponent";
import CompareCarResultComponent from "./components/CompareCarResultComponent";


import ForgotComponent from "./components/ForgotComponent";
import SideBarDealerComponent from "./components/SideBarDealerComponent";
import UploadFilesComponent from "./components/UploadFilesComponent";
import PrivacyPolicyComponentOld from "./components/PrivacyPolicyComponentOld";
import TermsAndConditionComponent from "./components/TermsAndConditionComponent";
import ImageUploadComponent from "./components/ImageUploadComponent";
import CityImageUploadComponent from "./components/CityImageUploadComponent";
import LocationComponent from "./components/LocationComponent";
import ViewInquiryComponent from "./components/ViewInquiryComponent";
import ListDealerInquiryComponent from "./components/ListDealerInquiryComponent";
import DashboardDealerComponent from "./components/DashboardDealerComponent";

import ListDealerBackendInquiryComponent from "./components/ListDealerBackendInquiryComponent";
import ListCarAvaibilityComponent from "./components/ListCarAvaibilityComponent";
import ListEquipmentComponent from "./components/ListEquipmentComponent";
import ViewEquipmentComponent from "./components/ViewEquipmentComponent";
import CreateEquipmentComponent from "./components/CreateEquipmentComponent";
import ChangePasswordComponent from "./components/ChangePasswordComponent";
import SpecificationComponent from "./components/SpecificationComponent";
import PriceComponent from "./components/PriceComponent";
import PicturesComponent from "./components/PicturesComponent";
import CancelationPolicyComponent from "./components/CancelationPolicyComponent";
import PrivacyPolicyComponent from "./components/PrivacyPolicyComponent";
import LegalDisclaimeComponent from "./components/LegalDisclaimeComponent";
import CreateUserComponent from "./components/CreateUserComponent";
import DisplayVariantComponent95 from "./components/DisplayVariantComponent955";
import ViewDealerInquiryComponent from "./components/ViewDealerInquiryComponent";



import ScriptTag from "react-script-tag";

function App() {
  return (
    <div>
      <div>
        <ScriptTag
          isHydrating={true}
          type="text/javascript"
          src="./mobhil/js/jquery.min.js"
        />
      </div>
      <Router>
        <div className="container-fluid">
          {/* <HeaderComponent /> */}

          <Switch>

          <Route
              path="/cancelation-policy"
              exact
              component={CancelationPolicyComponent}
            ></Route>

            <Route
              path="/display-old/:id"
              exact
              component={DisplayVariantComponent95
              }
            ></Route>
            <Route
              path="/car-listing"
              exact
              component={CarListingComponent}
            ></Route>
            <Route path="/" exact component={IndexComponent}></Route>

            <Route path="/login" exact component={LoginComponent}></Route>
            <Route
              path="/change-password"
              exact
              component={ChangePasswordComponent}
            ></Route>

            <Route path="/register" exact component={RegisterComponent}></Route>

            <Route path="/dashboard" component={DashboardComponent}></Route>
            <Route
              path="/dashboardDealer"
              component={DashboardDealerComponent}
            ></Route>
            <Route path="/employees" component={ListEmployeeComponent}></Route>
            <Route
              path="/add-employee/:id"
              component={CreateEmployeeComponent}
            ></Route>
            <Route
              path="/view-employee/:id"
              component={ViewEmployeeComponent}
            ></Route>
            {
              <Route
                path="/update-employee/:id"
                component={UpdateEmployeeComponent}
              ></Route>
            }

            <Route path="/customers" component={ListCustomerComponent}></Route>
           

            <Route
              path="/add-customer/:id"
              component={CreateCustomerComponent}
            ></Route>
            <Route
              path="/view-customer/:id"
              component={ViewCustomerComponent}
            ></Route>

            <Route path="/products" component={ListProductComponent}></Route>
            <Route
              path="/add-product/:id"
              component={CreateProductComponent}
            ></Route>
            <Route
              path="/view-product/:id"
              component={ViewProductComponent}
            ></Route>

            <Route path="/history" component={ListHistoryComponent}></Route>
            <Route
              path="/view-history/:id"
              component={ViewHistoryComponent}
            ></Route>

            <Route
              path="/rentalInformations"
              component={ListRentalInformationComponent}
            ></Route>
            <Route
              path="/add-rentalInformation/:id"
              component={CreateRentalInformationComponent}
            ></Route>
            <Route
              path="/view-rentalInformation/:id"
              component={ViewRentalInformationComponent}
            ></Route>

            <Route
              path="/add-brand/:id"
              component={CreateBrandComponent}
            ></Route>
            
            <Route path="/brands" 
             component={ListBrandComponent}>
             </Route>

            <Route
              path="/view-brand/:id"
              component={ViewBrandComponent}
            ></Route>

            <Route
              path="/add-model/:id"
              component={CreateModelComponent}
            ></Route>
            <Route path="/models" component={ListModelComponent}></Route>
            <Route
              path="/view-model/:id"
              component={ViewModelComponent}
            ></Route>

            <Route
              path="/display-variant-New/:id"
              component={DisplayVariantComponentNew}
            ></Route>
            <Route
              path="/display-variant/:id"
              component={DisplayVariantComponent}
            ></Route>
            <Route
              path="/display/:id"
              component={DisplayVComponent}
            ></Route>
            <Route
              path="/specifications/:id"
              component={SpecificationComponent}
            ></Route>
            <Route
              path="/price/:id"
              component={PriceComponent}
            ></Route>

<Route
              path="/pictures/:id"
              component={PicturesComponent}
            ></Route>

<Route
              path="/add-user/:id"
              component={CreateUserComponent}
            ></Route>
            <Route path="/users" component={ListUserComponent}></Route>
            <Route
              path="/view-users/:id"
              component={ViewUserComponent}
            ></Route>
          
          
          
          
            <Route
              path="/add-dealer/:id"
              component={CreateDealerComponent}
            ></Route>
            <Route path="/dealers" component={ListDealerComponent}></Route>
            <Route
              path="/view-dealer/:id"
              component={ViewDealerComponent}
            ></Route>

            <Route path="/add-city/:id" component={CreateCityComponent}></Route>
            <Route path="/citys" component={ListCityComponent}></Route>
            <Route path="/view-city/:id" component={ViewcityComponent}></Route>

            <Route
              path="/add-state/:id"
              component={CreateStateComponent}
            ></Route>
            <Route path="/states" component={ListStateComponent}></Route>
            <Route
              path="/view-state/:id"
              component={ViewStateComponent}
            ></Route>

            <Route
              path="/add-variant/:id"
              component={CreateVariantComponent}
            ></Route>
            <Route path="/variants" component={ListVariantComponent}></Route>
            <Route
              path="/view-variant/:id"
              component={ViewVariantComponent}
            ></Route>

            <Route
              path="/add-feature/:id"
              component={CreateFeatureComponent}
            ></Route>
            <Route path="/features" component={ListFeatureComponent}></Route>
            <Route
              path="/view-feature/:id"
              component={ViewFeatureComponent}
            ></Route>

            <Route
              path="/add-specification/:id"
              component={CreateSpecificationComponent}
            ></Route>
            <Route
              path="/specifications"
              component={ListSpecificationComponent}
            ></Route>
            <Route
              path="/view-specification/:id"
              component={ViewSpecificationComponent}
            ></Route>
            <Route
              exact
              path={["/", "/all_models"]}
              component={ModelComponent}
            />

            <Route
              exact
              path={["/", "/all_variants"]}
              component={VariantComponent}
            />
            <Route path="/upload" component={UploadFiles}></Route>
            <Route
              path="/add-color/:id"
              component={CreateColorComponent}
            ></Route>
            <Route path="/colors" component={ListColorComponent}></Route>
            <Route
              path="/view-color/:id"
              component={ViewColorComponent}
            ></Route>
             <Route
              path="/view-dealerInquiry/:id"
              component={ViewDealerInquiryComponent}
            ></Route>
            <Route
              path="/add-executive/:id"
              component={CreateExecutiveComponent}
            ></Route>
            <Route
              path="/executives"
              component={ListExecutiveComponent}
            ></Route>
            <Route
              path="/view-executive/:id"
              component={ViewExecutiveComponent}
            ></Route>
            <Route
              path="/add_image/:id"
              component={ImageUploadComponent}
            ></Route>
            <Route
              path="/add_city_image/:id"
              component={CityImageUploadComponent}
            ></Route>
            <Route
              path="/add-variant-specification/:id"
              component={CreateSpecificationValueComponent}
            ></Route>
            <Route
              path="/add-variant-feature/:id"
              component={CreateFeatureValueComponent}
            ></Route>
            <Route path="/about-us" component={AboutUsComponent}></Route>
            <Route path="/contact-us" component={ContactComponent}></Route>
            {/* <Route path="/contact-us/:id" component={ConComponent}></Route> */}
            <Route path="/compare" component={CompareCarComponent}></Route>
            <Route path="/compare-car-result/:id1/:id2/:id3" component={CompareCarResultComponent}></Route>
            <Route path="/forgot" exact component={ForgotComponent}></Route>
            <Route path="/1" exact component={SideBarDealerComponent}></Route>
            <Route
              path="/privacy-policy"
              
              component={PrivacyPolicyComponent}
            ></Route>


<Route
              path="/legal-disclaime"
              exact
              component={LegalDisclaimeComponent}
            ></Route>
            <Route
              path="/terms-and-condition"
              exact
              component={TermsAndConditionComponent}
            ></Route>
            <Route path="/location" exact component={LocationComponent}></Route>
            <Route
              path="/view-inquiry/:id/:inquiry/:brandId"
              exact
              component={ViewInquiryComponent}
            ></Route>
            <Route
              path="/dealer-inquiry"
              exact
              component={ListDealerInquiryComponent}
            ></Route>

            <Route
              path="/dealerbackend-inquiry"
              exact
              component={ListDealerBackendInquiryComponent}
            ></Route>

            <Route
              path="/carAvailability"
              exact
              component={ListCarAvaibilityComponent}
            ></Route>
            <Route
              path="/add-equipment/:id"
              exact
              component={CreateEquipmentComponent}
            ></Route>
            <Route
              path="/equipments"
              exact
              component={ListEquipmentComponent}
            ></Route>
            <Route
              path="/view-equipment/:id"
              exact
              component={ViewEquipmentComponent}
            ></Route>
          </Switch>
          {/* <FooterComponent /> */}
        </div>
      </Router>
    </div>
  );
}

export default App;
