import React, { Component } from "react";
import ModelService from "../services/ModelService";

import SideBarComponent from "./SideBarComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";
import DataTable from "react-data-table-component";
// import { confirm } from "react-confirm-box";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import {
  faEye,
  faEdit,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getByTitle } from "@testing-library/react";
import { Row } from "./bootstrap-component";

class ListModelComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
       tableData: [],
      selectedModelsRows: [],
      models: [],

      columns: [
        {
          name: "Id",
          selector: (row) => row.modelId,
          cellExport: (row) => row.modelId,
          sortable: true,
        },
        {
          name: "Model Name",
          selector: (row) => row.modelName,
          cellExport: (row) => row.modelName,
          sortable: true,
        },
        {
          name: "Brand Name",
          selector: (row) => row.brand.brandName,
          cellExport: (row) => row.brand.brandName,
          sortable: true,
        },
        {
          name: "Variants",
          selector: (row) => row.variants.length,
          cellExport: (row) => row.variants.length,
          sortable: true,
        },
        {
          name: "Created Date",
          selector: (row) => row.dateCreated,
          cellExport: (row) => row.dateCreated,
          sortable: true,
        },
        {
          name: "Updated Date",
          selector: (row) => row.dateUpdated,
          cellExport: (row) => row.dateUpdated,
          sortable: true,
        },

        {
          cell: (row) => (
            <a href={`/view-model/${row["modelId"]}`}>
              <FontAwesomeIcon icon={faEye} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          cell: (row) => (
            <a href={`/add-model/${row["modelId"]}`}>
              <FontAwesomeIcon icon={faEdit} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          cell: (row) => (
            <a href="#!" onClick={() => this.clickHandler(row)}>
              <FontAwesomeIcon icon={faTrash} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          cell: (row) => (
            <a href={`/add_image/${row["modelId"]}`}>
              <FontAwesomeIcon icon={faUpload} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
    };
    this.addModel = this.addModel.bind(this);
    this.editModel = this.editModel.bind(this);
    this.deleteModel = this.deleteModel.bind(this);
  }

  deleteModel(id) {
    ModelService.deleteModel(id).then((res) => {
      this.setState({
        models: this.state.models.filter((model) => model.modelId !== id),
      });
    });
    this.componentDidMount();
  }
  clickHandler = (row) => {
    this.deleteModel(row.modelId);
  };

  viewModel(id) {
    this.props.history.push(`/view-model/${id}`);
  }
  editModel(id) {
    this.props.history.push(`/add-model/${id}`);
  }
  uploadModelImage(id) {
    this.props.history.push(`/add_image/${id}`);
  }
  componentDidMount() {
    ModelService.getModels().then((res) => {
      this.setState({ models: res.data });
      this.setState({
        tableData: { columns: this.state.columns, data: res.data },
      });
    });
  }

  addModel() {
    this.props.history.push("/add-model/0");
  }

  render() {
    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" class="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Brand</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <h6 class="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">
                        List Model
                      </h6>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={this.addModel}
                      >
                        {" "}
                        Add Model
                      </button>
                    </div>
                    <div class="card-body">
                      {/* <h2 className="text-center">Models List</h2> */}
                      <div className="row">
                        <DataTableExtensions {...this.state.tableData}>
                          <DataTable
                            columns={this.state.columns}
                            data={this.state.models}
                            defaultSortField="Id"
                            defaultSortDesc={false}
                            pagination
                            fixedHeader
                            exportHeaders={Row.name}
                            fixedHeaderScrollHeight="300px"
                            highlightOnHover
                            selectableRows
                            print={false}
                            onSelectedRowsChange={this.handleChange}
                            selectedRows={this.handleChange}
                          />
                        </DataTableExtensions>
                      </div>
                      <br></br>
                      <div className="row">
                        {/* <table className = "table table-striped table-bordered">

                            <thead>
                                <tr>
                                <th> Model Id</th>
                                <th> Model Name</th>
                                <th> Brand Name</th>
                                <th> Variants</th>

                                <th> Created Date</th>
                                <th> Updated Date</th>
                                    <th> Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.models.map(
                                        model => 
                                        <tr key = {model.modelId}>
                                            <td> { model.modelId} </td>
                                             <td> { model.modelName} </td>   
                                             <td> { model.brand.brandName} </td>   
                                             <td> { model.variants.length} </td>   

                                             <td> { model.dateCreated} </td> 
                                             <td> { model.dateUpdated} </td> 
                                             <td>
                                                 <button onClick={ () => this.editModel(model.modelId)} className="btn btn-info"><FontAwesomeIcon icon={ faEdit }/> </button>
                                                 <button style={{marginLeft: "10px"}} onClick={ () => this.deleteModel(model.modelId)} className="btn btn-danger"><FontAwesomeIcon icon={ faTrash }/> </button>
                                                 <button style={{marginLeft: "10px"}} onClick={ () => this.viewModel(model.modelId)} className="btn btn-info"><FontAwesomeIcon icon={ faEye }/> </button>
                                                 <button style={{marginLeft: "10px"}} onClick={ () => this.uploadModelImage(model.modelId)} className="btn btn-info"><FontAwesomeIcon icon={ faUpload }/> </button>
                                             </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default ListModelComponent;
