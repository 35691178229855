import React, { Component } from "react";
import CustomerService from "../services/CustomerService";

import SideBarComponent from "./SideBarComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";
import DataTable from "react-data-table-component";
// import { confirm } from "react-confirm-box";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import {
  faSearch,
  faEye,
  faEdit,
  faTrash,
  faAlignRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ListCustomerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customer: [],
      currentUser: null,
      tableData: {},
      selctedCustomerRows: [],
      columns: [
        {
          name: "Customer Id",
          selector: (row) => row.id,
          cellExport: (row) => row.id,
          sortable: true,
        },
       
        {
          name: "Customer Name",
          selector: (row) => row.name,
          cellExport: (row) => row.name,
          sortable: true,
        },
        {
          name: "Customer Address",
          selector: (row) => row.address,
          cellExport: (row) => row.address,
          sortable: true,
        },
        {
          name: "Customer Email",
          selector: (row) => row.email,
          cellExport: (row) => row.email,
          sortable: true,
        },
        {
          name: " Customer Number",
          selector: (row) => row.mobile,
          cellExport: (row) => row.mobile,
          sortable: true,
        },

        {
          name: "Inquiry",
          selector: (row) => row.inquiry,
          cellExport: (row) => row.inquiry,
          sortable: true,
        },
        
        {
          name: " Inquiry Status",
          selector: (row) => row.inquiryStatus,
          cellExport: (row) => row.inquiryStatus,
          sortable: true,
        },
        // {
        //   name: "Customer Message",
        //   selector: (row) => row?.message,
        //   cellExport: (row) => row?.message,
        //   sortable: true,
        // },
        {
            name: " Created Date",
            selector: (row) => row.dateCreated,
            cellExport: (row) => row.dateCreated,
            sortable: true,
          },

        {
          cell: (row) => (
            <a href={`/view-customer/${row["id"]}`}>
              <FontAwesomeIcon icon={faEye} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          cell: (row) => (
            <a href={`/add-customer/${row["id"]}`}>
              <FontAwesomeIcon icon={faEdit} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          cell: (row) => (
            <a href="#!" onClick={() => this.clickHandler(row)}>
              <FontAwesomeIcon icon={faTrash} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
    };
    this.addCustomer = this.addCustomer.bind(this);
    this.editCustomer = this.editCustomer.bind(this);
    this.deleteCustomer = this.deleteCustomer.bind(this);
  }

  deleteCustomer(id) {
    CustomerService.deleteCustomer(id).then((res) => {
      this.setState({
        customer: this.state.customer.filter((customer) => customer.id !== id),
      });
      this.componentDidMount();
    });
  }
  clickHandler = (row) => {
    this.deleteCustomer(row.id);
  };

  viewInquiry(id, inquiry, brandId) {
    this.props.history.push(`/view-inquiry/${id}/${inquiry}/${brandId}`);
  }

  viewCustomer(id) {
    this.props.history.push(`/view-customer/${id}`);
  }
  editCustomer(id) {
    this.props.history.push(`/add-customer/${id}`);
  }

  componentDidMount() {
    CustomerService.getCustomers().then((res) => {
      this.setState({ customer: res.data });
      this.setState({
        tableData: { columns: this.state.columns, data: res.data },
      });
    });
  }

  addCustomer() {
    this.props.history.push("/add-customer/0");
  }

  render() {
    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" class="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Customer</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <h6 class="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">
                        List Customer
                        </h6>
                        <button
                          className="btn btn-sm btn-primary"
                      
                          onClick={this.addCustomer}
                        >
                          Add Customer
                        </button>
                     
                    </div>
                    <div class="card-body">
                      {/* <h2 className="text-center">Customers List</h2> */}
                      <div className="row">
                        <DataTableExtensions {...this.state.tableData}>
                          <DataTable
                            columns={this.state.columns}
                            export={true}
                            exportHeader={true}
                            data={this.state.features}
                            defaultSortField="Feature Id"
                            defaultSortDesc={false}
                            pagination
                            fixedHeader={true}
                            highlightOnHover
                            selectableRows
                            print={false}
                            onSelectedRowsChange={this.handleChange}
                            selectedRows={this.handleChange}
                          />
                        </DataTableExtensions>
                      </div>
                      <br></br>
                      
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default ListCustomerComponent;
