import axios from "axios";

const COLOR_API_BASE_URL =
  "https://api.richgoldshine.com/bsecars/api/v1/colors";

class ColorService {
  getAll(params) {
    return axios.get(
      "https://api.richgoldshine.com/bsecars/api/v1/all_colors",
      { params }
    );
  }

  getColors() {
    return axios.get(COLOR_API_BASE_URL);
  }

  createColor(color) {
    return axios.post(COLOR_API_BASE_URL, color);
  }

  getColorById(colorId) {
    return axios.get(COLOR_API_BASE_URL + "/" + colorId);
  }

  updateColor(color, colorId) {
    return axios.put(COLOR_API_BASE_URL + "/" + colorId, color);
  }

  deleteColor(colorId) {
    return axios.delete(COLOR_API_BASE_URL + "/" + colorId);
  }
}

export default new ColorService();
