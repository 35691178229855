import axios from "axios";

const BRAND_API_BASE_URL =
  "https://api.richgoldshine.com/bsecars/api/v1/brands";

class BrandService {
  getAll(params) {
    return axios.get(
      "https://api.richgoldshine.com/bsecars/api/v1/all_brands",
      { params }
    );
  }

  getBrands() {
    return axios.get(BRAND_API_BASE_URL);
  }

  createBrand(brand) {
    return axios.post(BRAND_API_BASE_URL, brand);
  }

  getBrandById(brandId) {
    return axios.get(BRAND_API_BASE_URL + "/" + brandId);
  }

  updateBrand(brand, brandId) {
    return axios.put(BRAND_API_BASE_URL + "/" + brandId, brand);
  }

  deleteBrand(brandId) {
    return axios.delete(BRAND_API_BASE_URL + "/" + brandId);
  }
}

export default new BrandService();
