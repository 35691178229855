import axios from "axios";

const BRAND_API_BASE_URL =
  "https://api.richgoldshine.com/bsecars/api/v1/specifications";

class SpecificationService {
  getSpecifications() {
    return axios.get(BRAND_API_BASE_URL);
  }

  createSpecification(specification) {
    return axios.post(BRAND_API_BASE_URL, specification);
  }

  getSpecificationById(specificationId) {
    return axios.get(BRAND_API_BASE_URL + "/" + specificationId);
  }

  updateSpecification(specification, specificationId) {
    return axios.put(BRAND_API_BASE_URL + "/" + specificationId, specification);
  }

  deleteSpecification(specificationId) {
    return axios.delete(BRAND_API_BASE_URL + "/" + specificationId);
  }
}

export default new SpecificationService();
