import axios from "axios";

export default axios.create({
  //baseURL: "https://api.richgoldshine.com/bsecars/api/v1/",
  // baseURL: "https://api.richgoldshine.com/bsecars/api/v1/auth",

  baseURL: "https://api.richgoldshine.com/bsecars/api/v1/",
  // baseURL: "https://api.richgoldshine.com/bsecars/api/v1/auth/",

  headers: {
    "Content-type": "application/json",
  },
});
