import React, { Component } from "react";
import DealerService from "../services/DealerService";
import SideBarDealerComponent from "./SideBarDealerComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";

class ViewDealerInquiryComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      dealerInquiry: {},
      status:'',
      currentUser: null,
    };
    this.viewDealers = this.viewDealers.bind(this);
    this.saveOrUpdateCarAvaibility = this.saveOrUpdateCarAvaibility.bind(this);
  }

  componentDidMount() {
    
    DealerService.getDealerInquiryById(this.state.id).then((res) => {
      console.log(res.data);
      this.setState({ dealerInquiry: res.data });
    });
  }


  
  saveOrUpdateCarAvaibility = (e) => {
    e.preventDefault();

    let CarAvaibility = {
      
      dealerInquiry: { id: this.state.id },
      status: this.state.status,
    };
    console.log("CarAvaibility=> " + JSON.stringify(CarAvaibility));
    // alert("City new Id-" + dealerInquiry);
    // post data to spring boot application
    DealerService.createAvailability(CarAvaibility).then((res) => {
      this.props.history.push("/dealerbackend-inquiry");
    });
  };
  viewDealers() {
    this.props.history.push("/dealerbackend-inquiry");
  }
  changeStatusIdHandler = (event) => {
    // alert( event.target.value)
    this.setState({ status: event.target.value });
  };
  cancel() {
    this.props.history.push("/dealerbackend-inquiry");
  }
  render() {
    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarDealerComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" class="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Dealer</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <h6 class="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">
                        View Inquiry{" "}
                      </h6>
                      <button
                        style={{ marginLeft: "850px" }}
                        className="btn btn-primary"
                        onClick={this.viewDealers}
                      >
                        {" "}
                        List Inquiry
                      </button>
                    </div>
                    <div class="card-body">
                      {/* <h2 className="text-center">Dealers List</h2> */}
                      <div className="row"></div>
                      <br></br>
                      <div className="card col-md-6 offset-md-3">
                        <h3 className="text-center"> View Dealer Details</h3>
                        <div className="card-body">
                          <div className="row">
                            <h6 class="">
                              <label>Car Name : </label>
                              {this.state.dealerInquiry?.customer?.inquiry}
                            </h6>
                          </div>

                          <div className="form-group">
                            <label> Dealer Status: </label>

                            <select
                              class="form-select col-12"
                              cityName="status"
                              aria-label="Default select example"
                              onChange={this.changeStatusIdHandler}
                            >
                              <option value="">Status</option>
                              <option value="YES">YES</option>
                              <option value="NO">NO</option>

                              
                            </select>
                          </div>

                          <button
                            className="btn btn-success"
                            onClick={this.saveOrUpdateCarAvaibility}
                          >
                            Send
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={this.cancel.bind(this)}
                            style={{ marginLeft: "10px" }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
    );
  }
}

export default ViewDealerInquiryComponent;
