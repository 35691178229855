import React, { Component } from "react";
import DealerService from "../services/DealerService";
import CustomerService from "../services/CustomerService";

import SideBarComponent from "./SideBarComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";
import CityService from "../services/CityService";

import Multiselect from "multiselect-react-dropdown";

class ViewCustomerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      customer: {},
      city: [],
      cities: [],
      selectedCities: [],
      citiesIdVal: [],
      citiesVal: [],
      allCities: [],
      allCitiesVal: [],

      customerId: [],
      cityId: [],
    };

    this.viewCustomers = this.viewCustomers.bind(this);
    this.saveOrUpdateDealer = this.saveOrUpdateDealer.bind(this);
  }
  componentDidMount() {
    CustomerService.getCustomerById(this.state.id).then((res) => {
      //  alert("Hello" + this.state.id);
      console.log("customer new  data", res.data);
      // alert("customer data" + res.data);
      this.setState({ customer: res.data });
    });

    CityService.getCitys().then((res) => {
      this.setState({ allCities: res.data });
      //  alert("Hello" + res.data);
    });
  }

  saveOrUpdateDealer = (e) => {
    e.preventDefault();
    // alert("City Id" + this.state.selectedCities);
    // alert("customer ID" + this.state.id);

    let dealerInquiry = {
      customer: { id: this.state.id },
      dealerLocation: this.state.selectedCities,
    };
    console.log("dealerInquirynEW=> " + JSON.stringify(dealerInquiry));
    // alert("City new Id-" + dealerInquiry);
    // post data to spring boot application
    DealerService.dealerInquiry(dealerInquiry).then((res) => {
      this.props.history.push("/customers");
    });
  };

  viewCustomers() {
    this.props.history.push("/customers");
  }

  cancel() {
    this.props.history.push("/customers");
  }

  changeonSelecteHandler = (event) => {
    this.setState({ selectedCities: event.target.value });
  };
  onSelect = (selectedList, selectedItem) => {
    this.setState({ selectedCities: selectedList });
  };

  render() {
    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" class="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Customer</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <h6 class="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">
                        View Customer
                        <button
                          className="btn btn-primary"
                          style={{ marginLeft: "800px" }}
                          onClick={this.viewCustomers}
                        >
                          List Customer
                        </button>
                      </h6>
                    </div>
                    <div class="card-body">
                      {/* <h2 className="text-center">Customers List</h2> */}
                      <div className="row"></div>
                      <br></br>
                      <div className="card col-md-6 offset-md-3">
                        <h3 className="text-center"> View Customer Details</h3>
                        <div className="card-body">
                          <div className="row">
                            <h6 class="">
                              <label> Customer Name : </label> &nbsp;{" "}
                              {this.state.customer.name}
                            </h6>
                          </div>
                          {/* <div className = "row">
                           
                           <label> Customer Address: </label><div> {this.state.customer.address}</div>
                       </div>
                        */}
                          <div className="row">
                            <h6 class="">
                              <label> Customer Email: </label>&nbsp;{" "}
                              {this.state.customer?.email}
                            </h6>
                          </div>
                          <div className="row">
                            <h6 class="">
                              <label> Customer Number: </label>&nbsp;{" "}
                              {this.state.customer?.mobile}
                            </h6>
                          </div>
                          <div className="row">
                            <h6 class="">
                              <label> Address: </label>&nbsp;{" "}
                              {this.state.customer?.address}
                            </h6>
                          </div>
                          <div className="row">
                            <h6 class="">
                              <label> Inquiry Status: </label>&nbsp;{" "}
                              {this.state.customer?.inquiryStatus}
                            </h6>
                          </div>
                          <div className="row">
                            <h6 class="">
                              <label>Inquiry Description: </label>&nbsp;{" "}
                              {this.state.customer?.inquiryDescription}
                            </h6>
                          </div>
                          <div className="row">
                            <h6 class="">
                              <label>Message: </label>&nbsp;{" "}
                              {this.state.customer?.message}
                            </h6>
                          </div>
                          <div className="row">
                            <h6 class="">
                              <label>Inquiry: </label>&nbsp;{" "}
                              {this.state.customer?.inquiry}
                            </h6>
                          </div>

                          <div className="form-group">
                            <label> Select City: </label>
                            <Multiselect
                              onChange={this.changeonSelecteHandler}
                              options={this.state.allCities}
                              value={this.state.city.map(
                                (cities) => cities.selectedCities
                              )} // Options to display in the dropdown
                              selectedValues={this.state.selectedCities} // Preselected value to persist in dropdown
                              onSelect={this.onSelect} // Function will trigger on select event
                              onRemove={this.onRemove} // Function will trigger on remove event
                              displayValue="cityName" // Property name to display in the dropdown options
                            />
                            <p className="text-danger">
                              {" "}
                              {this.state.citiesIdVal}
                            </p>
                          </div>

                          {/* <div className="form-group">
                            <label> Dealer City: </label>

                            <select
                              class="form-select col-12"
                              cityName="cityId"
                              aria-label="Default select example"
                              onChange={this.changeCityIdHandler}
                            >
                              <option value="">Select City</option>

                              {this.state.city.map((city) => (
                                <option
                                  value={city.cityId}
                                  selected={this.state.cityId == city.cityId}
                                >
                                  {city.cityName}
                                </option>
                              ))}
                            </select>
                          </div> */}

                          <button
                            className="btn btn-success"
                            onClick={this.saveOrUpdateDealer}
                          >
                            Send
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={this.cancel.bind(this)}
                            style={{ marginLeft: "10px" }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default ViewCustomerComponent;
