import axios from "axios";

const PASSWORD_API_BASE_URL =
  "https://api.richgoldshine.com/bsecars/api/v1/ChangePassword";

class PasswordService {
  getPasswords() {
    return axios.get(PASSWORD_API_BASE_URL);
  }

  createPassword(password) {
    return axios.post(PASSWORD_API_BASE_URL, password);
  }

  getPasswordById(passwordId) {
    return axios.get(PASSWORD_API_BASE_URL + "/" + passwordId);
  }

  updatePassword(password, passwordId) {
    return axios.put(PASSWORD_API_BASE_URL + "/" + passwordId, password);
  }

  deletePassword(passwordId) {
    return axios.delete(PASSWORD_API_BASE_URL + "/" + passwordId);
  }
}

export default new PasswordService();
