import React, { Component } from 'react'
import { Redirect } from "react-router-dom";
import ColorService from "../services/ColorService";

import CityService from "../services/CityService";
import UploadCityFileService from "../services/UploadCityFileService";

import SideBarComponent from './SideBarComponent';

import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';
import UploadFilesComponent from './UploadFilesComponent';







class CityImageUploadComponent extends Component {


  constructor(props) {
    super(props);
    this.selectFile = this.selectFile.bind(this);
    this.upload = this.upload.bind(this);

    this.state = {
      id: this.props.match.params.id,
      selectedFiles: undefined,
      currentFile: undefined,
      cityId: "",
      progress: 0,
      message: "",
      cityName:"",
                                                                                                                                                       
      fileInfos: [],
    };
  }

  componentDidMount() {
    // step 4
    if (this.state.id === '0') {


      return
    } else {
      CityService.getCityById(this.state.id).then((res) => {
        console.log(res.data);
        let city = res.data;
        this.setState({
          cityName: city.cityName,
        });
      });
    }


    UploadCityFileService.getFiles().then((response) => {
      console.log( "bb"+response.data);
      this.setState({
        fileInfos: response.data,
      });
    });
  }

  selectFile(event) {
    this.setState({
      selectedFiles: event.target.files,
    });
  }

  upload() {
    let currentFile = this.state.selectedFiles[0];


    alert("HELLO"+currentFile);

    this.setState({
      progress: 0,
      currentFile: currentFile,
    });

    UploadCityFileService.uploadBaseCityImage(this.state.id, currentFile, (event) => {
      this.setState({
        progress: Math.round((100 * event.loaded) / event.total),
      });
    })
      .then((response) => {

       // alert(JSON.stringify(response));
        this.setState({
          message: response.data.message,
        });
        return UploadCityFileService.getFiles();
      })
      .then((files) => {
        this.setState({
          fileInfos: files.data,
        });
      });
    

    this.setState({
      selectedFiles: undefined,
    });
  }
  
  render() {
    const {
      selectedFiles,
      currentFile,
      progress,
      message,
      fileInfos,
    } = this.state;

    return (

      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">

          {/* <!-- Main Content --> */}
          <div id="content">

            <TopBarComponent />


            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">

              {/* <!-- Page Heading --> */}
              {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Color</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div className="row">

                <div className="col-xl-12 col-lg-12">

                  {/* <!-- Area Chart --> */}
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                    <a className="btn btn-primary float-right   mr-3" href="/citys" role="button">List City</a>


                    </div>

                    
                    <div className="card-body">





                      {currentFile && (
                        <div className="progress">

                          <div
                            className="progress-bar progress-bar-info progress-bar-striped"
                            role="progressbar"
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: progress + "%" }}
                          >
                            {progress}%
                          </div>
                        </div>
                      )}

{/* <hr></hr> */}
                     <br></br>

                      <div className="card text-center">




                        <div className="card-body">
                          <label className="btn btn-default">
                          {this.state.cityName}
                            <input type="hidden" name="cityId d-flex justify-content-center" value={this.state.id} />
                            <br></br>

                            <input type="file" className='mt-4 float-center' onChange={this.selectFile} />
                            
                            
                          </label>
                          
                          <div className="alert alert-light  " role="alert">
                            {message}
                          </div>

                        
                          <button
                            className="btn btn-success float-center"
                            disabled={!selectedFiles}
                            onClick={this.upload}
                          >
                            Upload
                          </button>
                        </div>

                        </div>

                        <UploadFilesComponent dataFromParent={this.state.id}/>
                </div>
                  </div>



                </div>


              </div>

              {/* <!-- Content Row --> */}


              {/* <!-- Content Row --> */}


            </div>
            {/* <!-- /.container-fluid --> */}

          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />

        </div>
        {/* <!-- End of Content Wrapper --> */}

      </div>
      // <!-- End of Page Wrapper --> 










    );
  }
}

export default CityImageUploadComponent
