import http from "../http-common";

class UploadFilesService {
  uploadBase(modelId, file, onUploadProgress) {
    let formData = new FormData();
    

    // alert(JSON.stringify(modelId));
    // alert(file);
    
    formData.append("file", file);
    formData.append("modelId", modelId);
    return http.post("model_base_image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  upload(modelId, idx, file, onUploadProgress) {
    let formData = new FormData();
    

    // alert(JSON.stringify(modelId));
    // alert(file);
    
    formData.append("file", file);
    formData.append("modelId", modelId);
    return http.post("model_image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }
  getFiles(modelId) {
    //  alert(JSON.stringify(modelId));
    return http.get("model_get_images/"+modelId);
  }
}

export default new UploadFilesService();